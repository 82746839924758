import {loginBegin} from "../../api/loginBegin";
import {loginComplete} from "../../api/loginComplete";
import {get as getWebauthnCredential, PublicKeyCredentialWithAssertionJSON} from "@github/webauthn-json";

export async function loginWebauthn(email: string): Promise<any> {
    const apikey = this.apikey

    const challenge = await loginBegin(apikey, email);

    let assertion: PublicKeyCredentialWithAssertionJSON;
    let err;
    try {
        assertion = await getWebauthnCredential(challenge);
    } catch (e) {
        console.log(e)
        err = e
    }

    //api hit login finish
    return new Promise(function (resolve, reject) {
        if (!err && assertion) {
            resolve(loginComplete(apikey, email, challenge.state_id, assertion));
        } else {
            reject(err);
        }
    })
}