import {request} from '../../helpers/request';
import {getConfig} from '../../config';

const url = `${getConfig().apiUrl}/passkey/login/initialize`;

export function getPasskeyLogin(apikey: string, identifier?: string): Promise<any> {
    const queryObj = {};
    if (identifier) {
        queryObj["identifier"] = identifier;
    }

    return request({
        method: 'GET',
        credentials: "include",
        url,
        queryParams: queryObj,
        headers: {
            'X-API-Key': apikey,
        },
    });
}