import {postEmail} from "../api/postEmail";
import {webauthnRegistered} from "../api/webauthnCheck";
import {Defaults} from "../defaults";
import {addStyles} from "../helpers/common";
import {getProjectConfig} from "../helpers/projectConfig";
import {magicLink} from "./magicLink";
import {createBrandFooter} from "./ui-helpers/createBrandFooter";
import {createForm} from "./ui-helpers/createForm";
import {createLoader} from "./ui-helpers/createLoader";
import {webauthnRegister} from "./webauthn/registerPage";
import {authenticate} from "./webauthn/login";
import {facebookIcon, googleIcon, appleIcon} from "../helpers/svgIcons";
import {postOTPEmail} from "../api/postOTPEmail";
import {emailOTP} from "./emailOTP";
import {postOTPPhone} from "../api/postOTPPhone";
import {phoneOTP} from "./phoneOTP";

const intlTelInput = require('intl-tel-input')
const buildjs = require('intl-tel-input/build/js/utils')

export async function signInWithPhoneOTP(api_key?: string, redirecturl?: string, lang?: string, phone?: boolean, auth?: string,
                                         isError?: boolean, isDomainError?: boolean, isDisposableEmailError?: boolean,
                                         enableWebauthn?: boolean): Promise<any> {
    const apikey = this?.apikey ? this.apikey : api_key;
    const webauthn_forced = this?.config?.enable_webauthn;
    const language = this?.config?.language ? this?.config?.language : lang;

    function setHttp(link) {
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    }

    const redirect_url = this?.config?.redirect_url ? setHttp(this?.config?.redirect_url) : redirecturl;
    //const options=this.options;

    let projectConfig;
    try {
        projectConfig = await getProjectConfig(apikey, language);
    } catch (e) {
        console.error(e.message);
        return;
    }
    //check for webauthn enabled in config, if present go for webauthn registeration here
    const rootContainerId = Defaults.rootContainer;
    const loginFormId = Defaults.loginFormContainer;
    // let promise;
    const container = document.getElementById(rootContainerId);
    if (!container) {
        console.error('HTML container not in the DOM');
        return;
    }
    // add new font on the root container and add styles

    container.style.fontFamily = projectConfig.data.font.family;
    if (projectConfig.data.font.url) {
        addStyles(projectConfig.data.font.url);
    }

    const checkContainer = document.getElementById(loginFormId)
    if (checkContainer) {
        container.removeChild(checkContainer);
    }
    // Step 1: Create root login container


    const loginFormContainer = document.createElement('div');
    loginFormContainer.id = loginFormId;

    // Step 2: Create main login container
    const loginMainContainer = document.createElement('div');
    loginMainContainer.classList.add("mj-main-js", "mj-wrapper")

    // Step 3: Create card login container

    const loginCardContainer = document.createElement('div')
    loginCardContainer.id = Defaults.loginCardContainer
    loginCardContainer.classList.add("mj-card-js")
    loginCardContainer.classList.add("padding-lg-js")
    loginCardContainer.style.setProperty('--css-direction', projectConfig.data.localization.direction)
    loginCardContainer.style.setProperty('--custom-color', projectConfig.data.btn_color)
    // Step 4: Create header login container

    const loginHeaderContainer = document.createElement('div');
    loginHeaderContainer.classList.add("header")

    // Step 5: Create logo container

    const loginLogoContainer = document.createElement('div')
    loginLogoContainer.classList.add('logo')
    loginLogoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} alt="Company Name" />`
    projectConfig.data.brand_logo ? loginHeaderContainer.appendChild(loginLogoContainer) : null;

    // Step 7: Create title container

    const loginTitleContainer = document.createElement('div')
    loginTitleContainer.classList.add("title")
    loginTitleContainer.innerHTML = `<h1>${projectConfig.data.localization.login.heading + " " + projectConfig.data.brand}</h1>`

    projectConfig.data.brand ? loginHeaderContainer.appendChild(loginTitleContainer) : null;
    const loginSubTitleContainer = document.createElement('div')
    loginSubTitleContainer.classList.add("subtitle")
    loginSubTitleContainer.id = 'mojoauth-subtitle'
    loginHeaderContainer.appendChild(loginSubTitleContainer)
    loginCardContainer.appendChild(loginHeaderContainer)

    // Step 8: Add form

    const form = createForm(projectConfig.data, Defaults.loginFormId, apikey, projectConfig?.auth_config?.social,
        redirect_url, language, phone, auth, enableWebauthn || projectConfig.auth_config.webauthn)

    loginCardContainer.appendChild(form)
    if (isError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'error-message';
        errorMessage.textContent = `${projectConfig.data.localization.verification.default_error}`;
        loginCardContainer.insertBefore(errorMessage, form)
    }
    if (isDomainError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'domain-error-message';
        errorMessage.textContent = `Email address entered is not whitelisted`;
        loginCardContainer.insertBefore(errorMessage, form)
    }
    if (isDisposableEmailError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'domain-error-message';
        errorMessage.textContent = `The entered email address is either disposable or not allowed for login`;
        loginCardContainer.insertBefore(errorMessage, form)
    }

    //Step 9: Add optional social login

    // if(true){
    // const socialLoginContainer = document.createElement('div')
    // socialLoginContainer.classList.add('social-provider')
    // if(true){
    // 	const googleLoginContainer=document.createElement('div')
    // 	googleLoginContainer.classList.add('google')
    // 	googleLoginContainer.innerHTML=`<a href="#">
    //             <img src=${googleIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( googleLoginContainer )

    // }
    // if(true){
    // 	const facebookLoginContainer=document.createElement('div')
    // 	facebookLoginContainer.classList.add('google')
    // 	facebookLoginContainer.innerHTML=`<a href="#">
    //             <img src=${facebookIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( facebookLoginContainer )

    // }
    // if(true){
    // 	const appleLoginContainer=document.createElement('div')
    // 	appleLoginContainer.classList.add('google')
    // 	appleLoginContainer.innerHTML=`<a href="#">
    //             <img src=${appleIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( appleLoginContainer )

    // }
    // }

    // Step 9: Add brand footer
    if (!projectConfig.data.white_label) {
        loginCardContainer.appendChild(createBrandFooter())
    }
    loginMainContainer.appendChild(loginCardContainer)
    loginFormContainer.appendChild(loginMainContainer)

    // loginFormContainer.classList.add(
    // 	'mojoauth--card',
    // 	'mojoauth--shadow',
    // 	'mojoauth--v-center'
    // );

    // // Step 2: Add brand logo container
    // const logoContainer = document.createElement('div');
    // logoContainer.classList.add('logo');
    // logoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} />`;
    // loginFormContainer.appendChild(logoContainer);

    // // Step 3: Add title and subtitle
    // const formTitle = document.createElement('div');
    // formTitle.classList.add('title');
    // formTitle.textContent = projectConfig.data.localization.login.heading + " " + projectConfig.data.brand ;
    // loginFormContainer.appendChild(formTitle);

    // const formSubTitle = document.createElement('div');
    // formSubTitle.classList.add('subtitle');
    // formSubTitle.textContent = projectConfig.data.localization.login.sub_heading;
    // loginFormContainer.appendChild(formSubTitle);

    // // Step 4: Add form
    // const form = createForm(projectConfig.data, Defaults.loginFormId);
    // loginFormContainer.appendChild(form);


    // // Step 5: Add brand footer
    // loginFormContainer.appendChild(createBrandFooter());

    container.appendChild(loginFormContainer);
    const phoneNumber = phone ? intlTelInput(document.getElementById('mojoauth-passwordless-phone'), {
        preferredCountries: ['us', 'gb', 'in'],
        utilsScript: buildjs
// any initialisation options go here
    }) : null
    let dialCode = 1;
    let iso_code = "US";
    phone ? document.getElementById('mojoauth-passwordless-phone').addEventListener('countrychange', () => {

        dialCode = phoneNumber.getSelectedCountryData().dialCode;

        iso_code = phoneNumber.getSelectedCountryData().iso2.toUpperCase();
        document.getElementById('country-code-select').textContent = String('+' + dialCode);
    }) : null;

    let promise;
    !phone ? auth === 'magiclink' ? (
        promise = new Promise(function (resolve, reject) {
            if (enableWebauthn || projectConfig.auth_config.webauthn) {
                document.getElementById('mojoauth-passkey-signin-button').addEventListener("click", async () => {
                    resolve(authenticate(apikey).catch(async err => {
                        console.log(err);
                    }))
                });
            }
            if (auth) {
                document.getElementById('feature-switch').onclick = () => {
                    resolve(signInWithPhoneOTP(apikey, redirect_url, language, !phone, auth))
                }
            }
            form.onsubmit = async (e) => {
                e.preventDefault();
                if (document.getElementById('error-message')) document.getElementById('error-message').remove();
                createLoader(projectConfig.data.btn_color);
                const email = form.email.value
                const check = await webauthnRegistered(apikey, email);
                // Check if webauthn enabled from config or dashboard
                if (projectConfig.webauthn || webauthn_forced) {

                    // Check if user has already registered for webauthn credentials
                    if (check.webauthn_registered) {
                        //display here login page
                        resolve(authenticate(apikey, email)
                            .catch(async error => {
                                // Fallback if webauthn login fails
                                const emailResponse = await postEmail(apikey, email, language, redirect_url);
                                return magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig, true)
                            }))

                    }
                    // Verify user credentials with magiclink
                    else {
                        const emailResponse = await postEmail(apikey, email, language, redirect_url);
                        magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig, true)
                            .then(response => {
                                //Check if the user has visited first time
                                if (check.webauthn_flag && emailResponse.state_id) {

                                    // Display the webauthn register page

                                    webauthnRegister(apikey, email, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                                        .then(res => {

                                            resolve(res)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            resolve(response)
                                        })
                                }
                                //
                                else {
                                    resolve(response)
                                }
                            })
                    }
                } else {
                    const emailResponse = await postEmail(apikey, email, language, redirect_url);
                    resolve(magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig, true))
                }
            }
        })) : (
        promise = new Promise(function (resolve, reject) {
            if (enableWebauthn || projectConfig.auth_config.webauthn) {
                document.getElementById('mojoauth-passkey-signin-button').addEventListener("click", async () => {
                    resolve(authenticate(apikey).catch(async err => {
                        console.log(err);
                    }))
                });
            }
            if (auth) {
                document.getElementById('feature-switch').onclick = () => {
                    resolve(signInWithPhoneOTP(apikey, redirect_url, language, !phone, auth))
                }
            }
            form.onsubmit = async (e) => {
                e.preventDefault();
                if (document.getElementById('error-message')) document.getElementById('error-message').remove();
                createLoader(projectConfig.data.btn_color);
                const email = form.email.value
                const check = await webauthnRegistered(apikey, email);
                // Check if webauthn enabled from config or dashboard
                if (projectConfig.webauthn || webauthn_forced) {

                    // Check if user has already registered for webauthn credentials
                    if (check.webauthn_registered) {
                        //display here login page
                        resolve(authenticate(apikey, email)
                            .catch(async error => {
                                // Fallback if webauthn login fails
                                const emailResponse = await postOTPEmail(apikey, email, language);
                                return emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig, true)
                            }))

                    }
                    // Verify user credentials with magiclink
                    else {
                        const emailResponse = await postOTPEmail(apikey, email, language);
                        emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig, true)
                            .then(response => {
                                //Check if the user has visited first time

                                if (check.webauthn_flag && emailResponse.state_id) {
                                    // Display the webauthn register page

                                    webauthnRegister(apikey, email, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                                        .then(res => {

                                            resolve(res)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            resolve(response)
                                        })
                                }
                                //
                                else {
                                    resolve(response)
                                }
                            })
                    }
                } else {
                    const emailResponse = await postOTPEmail(apikey, email, language);
                    resolve(emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig, true))
                }
            }
        })) : (
        promise = new Promise(function (resolve, reject) {
            if (enableWebauthn || projectConfig.auth_config.webauthn) {
                document.getElementById('mojoauth-passkey-signin-button').addEventListener("click", async () => {
                    resolve(authenticate(apikey).catch(async err => {
                        console.log(err);
                    }))
                });
            }
            if (auth) {
                document.getElementById('feature-switch').onclick = () => {
                    resolve(signInWithPhoneOTP(apikey, redirect_url, language, !phone, auth))
                }
            }

            form.onsubmit = async (e) => {
                // function validatePhoneNumber(input_str) {
                // 	var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

                // 	return re.test(input_str);
                //   }
                // validatePhoneNumber(form.phone.value)
                e.preventDefault();
                if (document.getElementById('error-message')) document.getElementById('error-message').remove();
                const phoneNumber = '+' + dialCode + form.phone.value
                createLoader(projectConfig.data.btn_color);
                const check = await webauthnRegistered(apikey, phoneNumber);
                // Check if webauthn enabled from config or dashboard
                if (projectConfig.webauthn || webauthn_forced) {

                    // Check if user has already registered for webauthn credentials
                    if (check.webauthn_registered) {
                        //display here login page
                        resolve(authenticate(apikey, phoneNumber)
                            .catch(async error => {
                                // Fallback if webauthn login fails
                                const emailResponse = await postOTPPhone(apikey, phoneNumber, iso_code, language);
                                if (emailResponse.code) {

                                    resolve(signInWithPhoneOTP(apikey, redirect_url, language, phone, auth, true))

                                }

                                return phoneOTP(apikey, language, phoneNumber, emailResponse, projectConfig)
                            }))

                    }
                    // Verify user credentials with magiclink
                    else {

                        const emailResponse = await postOTPPhone(apikey, phoneNumber, iso_code, language)
                        if (emailResponse.code) {

                            resolve(signInWithPhoneOTP(apikey, redirect_url, language, phone, auth, true))

                        }
                        // if(!emailResponse){
                        // 	const errorCode = document.createElement('div');
                        // 	errorCode.innerHTML=`<p>Unable to send OTP, `
                        // 	container.append()
                        // }
                        phoneOTP(apikey, language, phoneNumber, emailResponse, projectConfig)
                            .then(response => {

                                //Check if the user has visited first time
                                if (check.webauthn_flag) {

                                    // Display the webauthn register page

                                    webauthnRegister(apikey, phoneNumber, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                                        .then(res => {

                                            resolve(res)
                                        })
                                        .catch(error => {
                                            console.log(error)
                                            resolve(response)
                                        })
                                }
                                //
                                else {

                                    resolve(response)
                                }
                            })
                    }
                } else {
                    const emailResponse = await postOTPPhone(apikey, phoneNumber, iso_code, language);
                    if (emailResponse.code) {

                        resolve(signInWithPhoneOTP(apikey, redirect_url, language, phone, auth, true))

                    }
                    resolve(phoneOTP(apikey, language, phoneNumber, emailResponse, projectConfig))
                }
            }
        })
    )


    // let promise=new Promise(function(resolve,reject){
    // form.onsubmit = async (e) => {
    // 	e.preventDefault();
    // 	createLoader(projectConfig.data.btn_color);
    // 	const email = form.email.value
    // 	const check= await webauthnRegistered(apikey,email);
    // 	// Check if webauthn enabled from config or dashboard
    //     if(projectConfig.webauthn||webauthn_forced){

    // 		// Check if user has already registered for webauthn credentials
    //         if(check.webauthn_registered){
    //             //display here login page
    //             resolve(authenticate(apikey,email)
    //             .catch(async error=>{
    // 				// Fallback if webauthn login fails
    //                 const emailResponse = await postEmail(apikey, email, language, redirect_url);
    //                 return magicLink(apikey,email,emailResponse,projectConfig)
    //             }))

    //         }
    // 		// Verify user credentials with magiclink
    //         else{
    //             const emailResponse = await postEmail(apikey, email, language, redirect_url);
    //             magicLink(apikey,email,emailResponse,projectConfig)
    //             .then(response=>{
    //                 //Check if the user has visited first time
    //                 if(check.first_login){
    // 					// Display the webauthn register page

    //                 webauthnRegister(apikey,email,check,projectConfig.data,response.oauth.access_token,emailResponse.state_id)
    //                 .then(res=>{

    //                     resolve(res)
    //                 })
    //                 .catch(error=>{console.log(error)
    // 					resolve(response)})
    //             }
    // 			//
    // 			else{
    //                 resolve(response)
    //             }
    //             })
    //         }
    //     }
    //     else{
    //         const emailResponse = await postEmail(apikey, email,language, redirect_url);
    //         resolve (magicLink(apikey,email,emailResponse,projectConfig))
    //     }
    // }
    // })
    return promise
}
