import { request } from '../../helpers/request';
import { getConfig } from '../../config';

const url = `${getConfig().apiUrl}/passkey/credentials/remove`;

export function deletePasskeyCredential(id: string, token: string): Promise<any> {
    const apikey = this.apikey;
    const requestUrl = `${url}/${id}`;
    return request({
        method: 'DELETE',
        url: requestUrl,
        headers: {
            'X-API-Key': apikey,
            Authorization: `Bearer ${token}`,
        },
    });
}
