import {request} from '../../helpers/request';
import {getConfig} from '../../config';
import {PublicKeyCredentialWithAttestationJSON} from "@github/webauthn-json";


const url = `${getConfig().apiUrl}/passkey/registration/finish`;

export function postAssertPasskeyRegistration(apikey: string, access_token: string, state_id: string, attestation: PublicKeyCredentialWithAttestationJSON): Promise<any> {

    return request({
        method: 'POST',
        credentials: "include",
        url,
        queryParams: {
            state_id
        },
        body: attestation,
        headers: {
            'X-API-Key': apikey,
            Authorization: `Bearer ${access_token}`,
        },
    });
}