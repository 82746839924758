import {postOTPEmail} from '../api/postOTPEmail';
import {createForm} from './ui-helpers/createForm';
import {getProjectConfig} from '../helpers/projectConfig';
import {Defaults} from '../defaults';
import {createBrandFooter} from './ui-helpers/createBrandFooter';
import {createLoader} from './ui-helpers/createLoader';
import {addStyles} from '../helpers/common';
import '../styles/main.scss';
import {webauthnRegistered} from '../api/webauthnCheck';
import {authenticate} from './webauthn/login';
import {webauthnRegister} from './webauthn/registerPage';
import {emailOTP} from './emailOTP';

export async function signInWithEmailOTP(api_key?: string, redirecturl?: string, lang?: string, isDomainError?: boolean, enableWebauthn?: boolean): Promise<any> {
    const apikey = this && this.apikey ? this.apikey : api_key;
    const webauthn_forced = this && this.config?.enable_webauthn;
    const language = this && this.config?.language ? this.config?.language : lang;
    const redirect_url = this && this.config?.redirect_url ? setHttp(this.config?.redirect_url) : redirecturl;

    function setHttp(link) {
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    }

    let projectConfig;
    try {
        projectConfig = await getProjectConfig(apikey, language);
    } catch (e) {
        console.error(e.message);
        return;
    }
    const rootContainerId = Defaults.rootContainer;
    const loginFormId = Defaults.loginFormContainer;
    // let promise;
    const container = document.getElementById(rootContainerId);
    if (!container) {
        console.error('HTML container not in the DOM');
        return;
    }
    // add new font on the root container and add styles
    container.style.fontFamily = projectConfig.data.font.family;
    if (projectConfig.data.font.url) {
        addStyles(projectConfig.data.font.url);
    }
    // Step 1: Create root login container form
    // const loginFormContainer = document.createElement('div');
    // loginFormContainer.id = loginFormId;
    // loginFormContainer.classList.add(
    // 	'mojoauth--card',
    // 	'mojoauth--shadow',
    // 	'mojoauth--v-center'
    // );

    // // Step 2: Add brand logo container
    // const logoContainer = document.createElement('div');
    // logoContainer.classList.add('logo');
    // logoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} />`;
    // loginFormContainer.appendChild(logoContainer);

    // // Step 3: Add title and subtitle
    // const formTitle = document.createElement('div');
    // formTitle.classList.add('title');
    // formTitle.textContent = projectConfig.data.localization.login.heading+ " " + projectConfig.data.brand;
    // loginFormContainer.appendChild(formTitle);

    // const formSubTitle = document.createElement('div');
    // formSubTitle.classList.add('subtitle');
    // formSubTitle.textContent = projectConfig.data.localization.login.sub_heading;
    // loginFormContainer.appendChild(formSubTitle);

    // // Step 4: Add form
    // const form = createForm(projectConfig.data, Defaults.loginFormId);
    // loginFormContainer.appendChild(form);

    // container.appendChild(loginFormContainer);

    // // Step 5: Add brand footer
    // loginFormContainer.appendChild(createBrandFooter());

    // Step 1: Create root login container

    const loginContainer = document.getElementById(loginFormId);
    if (loginContainer) {
        container.removeChild(loginContainer);
    }
    const loginFormContainer = document.createElement('div');
    loginFormContainer.id = loginFormId;

    // Step 2: Create main login container

    const loginMainContainer = document.createElement('div');
    loginMainContainer.classList.add("mj-main-js", "mj-wrapper")

    // Step 3: Create card login container

    const loginCardContainer = document.createElement('div')
    loginCardContainer.id = Defaults.loginCardContainer
    loginCardContainer.classList.add("mj-card-js")
    loginCardContainer.classList.add("padding-lg-js")
    loginCardContainer.style.setProperty('--css-direction', projectConfig.data.localization.direction)
    loginCardContainer.style.setProperty('--custom-color', projectConfig.data.btn_color)
    // Step 4: Create header login container

    const loginHeaderContainer = document.createElement('div');
    loginHeaderContainer.classList.add("header")

    // Step 5: Create logo container

    const loginLogoContainer = document.createElement('div')
    loginLogoContainer.classList.add('logo')
    loginLogoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} alt="Company Name" />`
    projectConfig.data.brand_logo ? loginHeaderContainer.appendChild(loginLogoContainer) : null;

    // Step 7: Create title container

    const loginTitleContainer = document.createElement('div')
    loginTitleContainer.classList.add("title")
    loginTitleContainer.innerHTML = `<h1>${projectConfig.data.localization.login.heading + " " + projectConfig.data.brand}</h1>`

    projectConfig.data.brand ? loginHeaderContainer.appendChild(loginTitleContainer) : null;
    const loginSubTitleContainer = document.createElement('div')
    loginSubTitleContainer.classList.add("subtitle")
    loginSubTitleContainer.id = 'mojoauth-subtitle'
    loginHeaderContainer.appendChild(loginSubTitleContainer)
    loginCardContainer.appendChild(loginHeaderContainer)

    // Step 8: Add form

    const form = createForm(projectConfig.data, Defaults.loginFormId, apikey, projectConfig?.auth_config?.social, redirect_url, language, false, null, enableWebauthn || projectConfig.auth_config.webauthn)
    loginCardContainer.appendChild(form)

    if (isDomainError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'domain-error-message';
        errorMessage.textContent = `Email address entered is not whitelisted`;
        loginCardContainer.insertBefore(errorMessage, form)
    }
    // Step 9: Add brand footer

    if (!projectConfig.data.white_label) {
        loginCardContainer.appendChild(createBrandFooter())
    }
    loginMainContainer.appendChild(loginCardContainer)
    loginFormContainer.appendChild(loginMainContainer)
    container.appendChild(loginFormContainer);

    return new Promise(function (resolve, reject) {
        if (enableWebauthn || projectConfig.auth_config.webauthn) {
            document.getElementById('mojoauth-passkey-signin-button').addEventListener("click", async () => {
                resolve(authenticate(apikey).catch(async err => {
                    console.log(err);
                }))
            });
        }
        form.onsubmit = async (e) => {
            e.preventDefault();

            createLoader(projectConfig.data.btn_color);
            const email = form.email.value
            const check = await webauthnRegistered(apikey, email);

            if (projectConfig.webauthn || webauthn_forced) {

                if (check.webauthn_registered) {
                    //display here login page
                    resolve(authenticate(apikey, email)
                        .catch(async error => {
                            const emailResponse = await postOTPEmail(apikey, email, language);
                            return emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig)
                        }))

                } else {
                    const emailResponse = await postOTPEmail(apikey, email, language);
                    emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig)
                        .then(response => {
                            //display here registeration page on condition
                            if (check.webauthn_flag && emailResponse.state_id) {
                                webauthnRegister(apikey, email, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                                    .then(res => {
                                        resolve(res)
                                    })
                                    .catch(error => resolve(response))
                            } else {
                                resolve(response)
                            }
                        })
                }
            } else {
                const emailResponse = await postOTPEmail(apikey, email, language);
                resolve(emailOTP(apikey, redirect_url, language, email, emailResponse, projectConfig))
            }
        }
    })
}