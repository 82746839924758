import {signInWithEmailOTP} from "./signInWithEmailOTP";
import {signInWithPhoneOTP} from "./signInWithPhoneOTP";
import {signInWithMagicLink} from "./singInWithMagicLink";

export async function signIn(): Promise<any> {

    const apikey = this.apikey;
    const language = this.config?.language;
    const source = this.config?.source;
    const enableWebauthn = this.config?.enable_webauthn;

    function setHttp(link) {
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    }

    const redirect_url = this.config?.redirect_url ? setHttp(this.config?.redirect_url) : null;

    const promise = new Promise(function (resolve, reject) {
        if (source && (source.find((obj1) => {
            return obj1.type === 'phone' && obj1.feature === 'otp'
        }) ? true : false) && (source.find((obj2) => {
            return obj2.type === 'email' && obj2.feature === 'otp'
        }) ? true : false)) {

            signInWithPhoneOTP(apikey, redirect_url, language, false, 'otp',false, false,
                false, enableWebauthn).then(response => {
                resolve(response);
            });

        } else if (source && (source.find((obj) => {
            return obj.type === 'phone' && obj.feature === 'otp'
        }) ? true : false) && (source.find((obj) => {
            return obj.type === 'email' && obj.feature === 'magiclink'
        }) ? true : false)) {

            signInWithPhoneOTP(apikey, redirect_url, language, false, 'magiclink', false,
                false, false, enableWebauthn).then(response => {
                resolve(response);
            });
        } else if (source && source.find((obj) => {
            return obj.type === 'email' && obj.feature === 'magiclink'
        }) ? true : false) {

            signInWithMagicLink(apikey, redirect_url, language, false, false, enableWebauthn).then(response => {
                resolve(response)
            });
        } else if (source && source.find((obj) => {
            return obj.type === 'email' && obj.feature === 'otp'
        }) ? true : false) {

            signInWithEmailOTP(apikey, redirect_url, language, false, enableWebauthn).then(response => {
                resolve(response)
            });
        } else if (source && source.find((obj) => {
            return obj.type === 'phone' && obj.feature === 'otp'
        }) ? true : false) {

            signInWithPhoneOTP(apikey, redirect_url, language, true, null, false, false, false, enableWebauthn).then(response => {
                resolve(response)
            });
        } else {

            signInWithMagicLink(apikey, redirect_url, language, false, false, enableWebauthn).then(response => {
                resolve(response)
            });
        }

    })

    return promise
}

//     const rootContainerId = Defaults.rootContainer;
// 	const loginFormId = Defaults.loginFormContainer;
// 	const container = document.getElementById(rootContainerId);
// 	if (!container) {
// 		console.error('HTML container not in the DOM');
// 		return;
// 	}
// 	// add new font on the root container and add styles

// 	container.style.fontFamily = projectConfig.data.font.family;
// 	if (projectConfig.data.font.url) {
// 		addStyles(projectConfig.data.font.url);
// 	}

// 	// Step 1: Create root login container 

// 	const loginFormContainer = document.createElement('div');
// 	loginFormContainer.id = loginFormId;

// 	// Step 2: Create main login container 

// 	const loginMainContainer = document.createElement('div');
// 	loginMainContainer.classList.add("main-js")

// 	// Step 3: Create card login container 

// 	const loginCardContainer = document.createElement('div')
// 	loginCardContainer.id=Defaults.loginCardContainer
// 	loginCardContainer.classList.add("card-js") 
// 	loginCardContainer.classList.add("padding-lg-js")

// 	// Step 4: Create header login container 

// 	const loginHeaderContainer = document.createElement('div');
// 	loginHeaderContainer.classList.add("header")

// 	// Step 5: Create logo container 

// 	const loginLogoContainer = document.createElement('div')
// 	loginLogoContainer.classList.add('logo')
// 	loginLogoContainer.innerHTML=`<img src=${projectConfig.data.brand_logo} alt="Company Name" />`
// 	loginHeaderContainer.appendChild(loginLogoContainer)

// 	// Step 7: Create title container 

// 	const loginTitleContainer = document.createElement('div')
// 	loginTitleContainer.classList.add("title")
// 	loginTitleContainer.innerHTML=`<h1>${projectConfig.data.localization.login.heading+ " " + projectConfig.data.brand}</h1>`

// 	loginHeaderContainer.appendChild(loginTitleContainer)
// 	loginCardContainer.appendChild(loginHeaderContainer)

// 	// Step 8: Add form

// 	const form = createForm(projectConfig.data, Defaults.loginFormId,apikey,projectConfig?.auth_config?.social,redirect_url, null, source)
// 	loginCardContainer.appendChild(form)
//     loginCardContainer.appendChild(createBrandFooter())
// 	loginMainContainer.appendChild(loginCardContainer)
// 	loginFormContainer.appendChild(loginMainContainer)

//     container.appendChild(loginFormContainer);


//     let promise=new Promise(function(resolve,reject){
//         document.getElementById(Defaults.loginFormId).onsubmit = async (e) => {
//             e.preventDefault();
//             createLoader(projectConfig.data.btn_color);
//             const email = form.email.value
//             const check= await webauthnRegistered(apikey,email);
//             // Check if webauthn enabled from config or dashboard
//             if(projectConfig.webauthn||webauthn_forced){

//                 // Check if user has already registered for webauthn credentials
//                 if(check.webauthn_registered){
//                     //display here login page
//                     resolve(authenticate(apikey,email)
//                     .catch(async error=>{
//                         // Fallback if webauthn login fails
//                         const emailResponse = await postEmail(apikey, email, language, redirect_url);
//                         return magicLink(apikey,email,emailResponse,projectConfig)
//                     }))

//                 }
//                 // Verify user credentials with magiclink 
//                 else{
//                     const emailResponse = await postEmail(apikey, email, language, redirect_url);
//                     magicLink(apikey,email,emailResponse,projectConfig)
//                     .then(response=>{
//                         //Check if the user has visited first time
//                         if(check.first_login){
//                             // Display the webauthn register page

//                         webauthnRegister(apikey,email,check,projectConfig.data,response.oauth.access_token,emailResponse.state_id)
//                         .then(res=>{

//                             resolve(res)
//                         })
//                         .catch(error=>{console.log(error)
//                             resolve(response)})
//                     }
//                     // 
//                     else{
//                         resolve(response)
//                     }
//                     })
//                 }
//             }
//             else{
//                 const emailResponse = await postEmail(apikey, email,language, redirect_url);
//                 resolve (magicLink(apikey,email,emailResponse,projectConfig))
//             }
//         }
//         })
//         return promise
// }