interface configSchema {
    apiUrl: string;
}

// TODO: Fix api url not coming from node env
export function getConfig(): configSchema {
    return {

        apiUrl: __API_URL__ || 'https://dev-core-api.mojoauth.com',
    };
}


export function userConfig(host) {
    return {
        height: host.getAttribute("height"),
        width: host.getAttribute("width"),
        position: host.getAttribute("position")
    }
}