import {request} from '../../helpers/request';
import {getConfig} from '../../config';
import {PublicKeyCredentialWithAssertionJSON} from "@github/webauthn-json";

const url = `${getConfig().apiUrl}/passkey/login/finish`;

export function postAssertPasskeyLogin(apikey: string, identifier: string, state_id: string, assertion: PublicKeyCredentialWithAssertionJSON): Promise<any> {
    const queryObj = {state_id: state_id};
    if (identifier) {
        queryObj["identifier"] = identifier;
    }

    return request({
        method: 'POST',
        credentials: "include",
        url,
        queryParams: queryObj,
        body: assertion,
        headers: {
            'X-API-Key': apikey,
        },
    });
}