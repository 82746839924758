import { request } from '../helpers/request';
import { getConfig } from '../config';

const url = `${getConfig().apiUrl}/users/status`;

export async function ping(
	apikey: string,
	state_id: string,
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	callback?: any
): Promise<any> {
	const response = await request({
		method: 'GET',
		url,
		queryParams: {
			state_id,
		},
		headers: {
			'X-API-Key': apikey,
		},
	});
	if (response.authenticated) {
		// return Promise.resolve(response);
		callback(response);
	} else {
		setTimeout(() => ping(apikey, state_id, callback), 5000);
	}
}
