// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { postOTPEmail } from '../api/postOTPEmail';
import { createForm } from './ui-helpers/createForm';
import { getProjectConfig } from '../helpers/projectConfig';
import { Defaults } from '../defaults';
import { createBrandFooter } from './ui-helpers/createBrandFooter';
import { createLoader } from './ui-helpers/createLoader';
import { createOTPVerificationPage } from './ui-helpers/createOTPVerificationPage';
import { addStyles } from '../helpers/common';
import '../styles/main.scss';
import { signInWithEmailOTP } from './signInWithEmailOTP';
import { signInWithPhoneOTP } from './signInWithPhoneOTP';

export async function emailOTP(apikey:string,redirect_url:string, language:string,email:string ,emailResponse:object,projectConfig, isPhoneUI?: boolean): any {

	// Step 5: Add brand footer
	let promise=new Promise(function(resolve,reject){
		const callback=(response: any)=>{
		resolve(response)
	}
		// createLoader(projectConfig.data.btn_color);
		if (emailResponse.state_id) {
			// show otp verification ui here
			createOTPVerificationPage({
				type: 'email',
				state_id: emailResponse.state_id,
				value: email,
				callback,
				apikey,
				language,
				projectConfig,
			});
		}else if(emailResponse.code===921){
			
			
			// createPostMagicLinkLoginPage(apikey,email,emailResponse.state_id, projectConfig);

			isPhoneUI?
				(
					signInWithPhoneOTP(apikey, redirect_url, language, false, 'otp', false, true).then(response=>{resolve(response)})
				)
			:
				(
					signInWithEmailOTP(apikey,redirect_url,language,true).then(response=>resolve(response))
				)

			
		}
})
return promise
}
