import {Defaults} from '../../defaults';
import {createBrandFooter} from './createBrandFooter';
import '../../styles/main.scss';
import {resendEmail} from "../../api/resendEmail";

function formatTimeLeft(time) {
    // The largest round integer less than or equal to the result of time divided being by 60.
    const minutes = Math.floor(time / 60);

    // Seconds are the remainder of the time divided by 60 (modulus operator)
    const seconds = time % 60;
    // If the value of seconds is less than 10, then display seconds with a leading zero

    // The output in MM:SS format
    return `${(minutes).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })}:${(seconds).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`;
}

export function createPostMagicLinkLoginPage(apikey: string, language: string, redirect_url: string, email: string, state_id: string, projectConfig): Promise<any> {
    const rootContainerId = Defaults.rootContainer;
    const loginFormId = Defaults.loginFormContainer;
    const postLoginId = Defaults.postLoginContainerId;
    const container = document.getElementById(rootContainerId);
    if (!container) {
        console.error('HTML container not in the DOM');
        return;
    }
    const loginFormContainer = document.getElementById(loginFormId);
    if (loginFormContainer) {
        container.removeChild(loginFormContainer);
    }
    const resendButtonDiv = document.createElement('div')
    const postLoginContainer = document.createElement('div');
    postLoginContainer.id = postLoginId;
    // postLoginContainer.classList.add(
    // 	'mojoauth--card',
    // 	'mojoauth--shadow',
    // 	'text-center',
    // 	'mojoauth--v-center'
    // );

    const loginMainContainer = document.createElement('div');
    loginMainContainer.classList.add("mj-main-js")
    // Step 3: Create card login container form
    const loginCardContainer = document.createElement('div')
    loginCardContainer.id = Defaults.loginCardContainer
    loginCardContainer.classList.add("mj-card-js")
    loginCardContainer.classList.add("padding-lg-js")
    loginCardContainer.style.setProperty('--custom-color', projectConfig.data.btn_color)

    loginCardContainer.innerHTML = `
	<div class="mj-alert">
    <p>${projectConfig.data.localization.verification.help_text}</p>
</div>
	
		<div class="logo">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.cls-1{fill:#f8f8f8;}.cls-2{fill:${projectConfig.data.btn_color};}.cls-3{fill:${projectConfig.data.btn_color};}.cls-4{fill:${projectConfig.data.btn_color};}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" width="150" height="150" rx="30"/><path class="cls-2" d="M75,85.83a15.1,15.1,0,0,1-6.67-1.33v4.67H80v-4A18,18,0,0,1,75,85.83Z"/><path class="cls-3" d="M115,65c0-8.75-17.92-15.83-40-15.83S35,56.25,35,65a2.3,2.3,0,0,0,0,.37,3.54,3.54,0,0,0,0,.46V107.5a6.69,6.69,0,0,0,6.67,6.67h66.66A6.69,6.69,0,0,0,115,107.5V65.83a3.54,3.54,0,0,0,0-.46A2.3,2.3,0,0,0,115,65ZM80,89.17H68.33V84.5A15.1,15.1,0,0,0,75,85.83a18,18,0,0,0,5-.66Z"/><path class="cls-1" d="M108.33,67.38V71L105,72.6l-6.67,3.28L74.73,87.5,51.67,76l-10-5V67.38A4.9,4.9,0,0,1,45,62.75a4.62,4.62,0,0,1,1.55-.25h56.9a4.62,4.62,0,0,1,1.55.25A4.9,4.9,0,0,1,108.33,67.38Z"/><path class="cls-4" d="M93.17,35.83H56.83A11.86,11.86,0,0,0,45,47.67v25L51.67,76,74.73,87.5l23.6-11.62L105,72.6V47.67A11.86,11.86,0,0,0,93.17,35.83Z"/><path class="cls-1" d="M98.33,46.42V75.88L74.73,87.5,51.67,76V46.42a3.93,3.93,0,0,1,3.91-3.92H94.42A3.93,3.93,0,0,1,98.33,46.42Z"/><path class="cls-3" d="M80,85.17v4H68.33V84.5A15.1,15.1,0,0,0,75,85.83,18,18,0,0,0,80,85.17Z"/><path class="cls-3" d="M74.39,48.05a13.5,13.5,0,1,0,13.5,13.5A13.51,13.51,0,0,0,74.39,48.05Zm7,9L72.69,67.41a1.06,1.06,0,0,1-.78.37h0a1,1,0,0,1-.77-.35l-3.74-4.15a1,1,0,0,1,1.52-1.43l0,0,2.94,3.27,7.95-9.48A1,1,0,0,1,81.41,57Z"/></g></g></svg>
		</div>
		<div class="title">${projectConfig.data.localization.verification.heading}</div>
		<div class="subtitle">
			${projectConfig.data.localization.verification.sub_heading} ${email}
		</div>
  `;
    const buttonName = `${projectConfig.data.localization.verification.button.resend}`;
    const ResendMailLink = document.createElement('input');
    ResendMailLink.classList.add('resend-link')
    ResendMailLink.id = "resend";
    ResendMailLink.type = 'submit';
    ResendMailLink.name = 'submit';
    ResendMailLink.value = buttonName;
    ResendMailLink.style.color = projectConfig.data.btn_color;
    // ResendMailButton.disabled="true";
    // setTimeout(function(){ResendMailButton.disabled = false;},10000);
    loginCardContainer.appendChild(ResendMailLink);

    if (!projectConfig.data.white_label) {
        loginCardContainer.appendChild(createBrandFooter());
    }

    loginMainContainer.appendChild(loginCardContainer)
    postLoginContainer.appendChild(loginMainContainer)

    container.appendChild(postLoginContainer);

    function startTimer() {
        const TIME_LIMIT = 30;
        let timeLeft = TIME_LIMIT;
        let timerInterval = null;
        let timePassed = 0;
        timerInterval = setInterval(() => {

            // The amount of time passed increments by one
            timePassed = timePassed += 1;
            timeLeft = TIME_LIMIT - timePassed;
            // The time left label is updated
            if (timeLeft > 0) {
                if (document.getElementById('resend')) {
                    (document.getElementById('resend') as any).value = ` ${projectConfig.data.localization.verification.button.resend_again} ${formatTimeLeft(timeLeft)} `;
                    (document.getElementById('resend') as any).disabled = true;
                    document.getElementById('resend').style.color = 'grey';
                    document.getElementById('resend').classList.add('disabled');
                }
            } else if (timeLeft <= 0) {
                if (document.getElementById('resend')) {
                    (document.getElementById('resend') as any).value = `${projectConfig.data.localization.verification.button.resend}`;
                    // (document.getElementById('resend')as any).value= `${projectConfig.data.localization.verification.button.resend}`;
                    (document.getElementById('resend') as any).disabled = false;
                    document.getElementById('resend').style.color = projectConfig.data.btn_color;
                    document.getElementById('resend').classList.remove('disabled');
                }
                clearInterval(timerInterval)
            }
        }, 1000);

    }

    startTimer();
    const promise = new Promise(function (resolve, reject) {
        ResendMailLink.onclick = async (e) => {

            e.preventDefault();
            // createLoader(projectConfig.data.btn_color);
            // (document.getElementById('resend')as any).disabled=true;
            // document.getElementById('resend').style.color='grey';
            // document.getElementById('resend').classList.add('disabled');
            startTimer();
            await resendEmail(apikey, language, redirect_url, state_id);
            // 	setTimeout(function(){
            // 		(document.getElementById('resend')as any).value= `${projectConfig.data.localization.verification.button.resend}`;
            // 		(document.getElementById('resend')as any).disabled=false;
            // 		document.getElementById('resend').style.color=projectConfig.data.btn_color;
            // 		document.getElementById('resend').classList.remove('disabled');
            // 		// (document.getElementById('resend')as any).value= `${projectConfig.data.localization.verification.button.resend}`

            // },31000);
        }
    })
    return promise;
}
