import {request} from '../helpers/request';
import {getConfig} from '../config';

const url = `${getConfig().apiUrl}/webauthn/registration/initialize`;

export function registrationBegin(apikey: string, access_token: string): Promise<any> {
    return request({
        method: 'GET',
        credentials: "include",
        url,
        headers: {
            'X-API-Key': apikey,
            Authorization: `Bearer ${access_token}`,
        },
    });
}
