import { getConfig } from '../config';
import { request } from '../helpers/request';

const url = `${getConfig().apiUrl}/users/magiclink/resend`;

/**
 *
 * @param apiKey
 * @param state_id
 * @returns
 * @description post email to get a magic link on the user's email
 */
export function resendEmail(apikey: string, language:string, redirect_url:string,  state_id: string): any {
	return request({
		method: 'POST',
		url,
		queryParams:{
			language,
			redirect_url,
		},
		body: {
			state_id,
		},

		headers: {
			'X-API-Key': apikey,
		},
	});
}
