import {request} from '../helpers/request';
import {getConfig} from '../config';

const url = `${getConfig().apiUrl}/webauthn/check`;

export function checkWebauthnStatus(identifier: string): Promise<any> {
    const apikey = this.apikey;
    return webauthnRegistered(apikey, identifier);
}

export function webauthnRegistered(apikey: string, identifier: string): Promise<any> {
    return request({
        method: 'GET',
        url,
        queryParams: {
            identifier,
        },
        headers: {
            'X-API-Key': apikey,
        },
    });
}