import {request} from '../helpers/request';
import {getConfig} from '../config';
import {PublicKeyCredentialWithAssertionJSON} from "@github/webauthn-json";

export function loginComplete(apikey: string, identifier: string, state_id: string, assertion: PublicKeyCredentialWithAssertionJSON): Promise<any> {

    return request({
        method: 'POST',
        credentials: "include",
        url: `${getConfig().apiUrl}/webauthn/login/finish`,
        queryParams: {
            state_id,
            identifier: identifier ? identifier : ""
        },
        body: assertion,
        headers: {
            'X-API-Key': apikey,
        },
    });
}
export function authStatus(apikey: string, identifier: string): Promise<any> {

    return request({
        method: 'POST',
        url: `${getConfig().apiUrl}/auth/status`,
        headers: {
            'x-api-key': apikey,
            'Content-Type': 'application/json',
        },
        body: {
            identifier: identifier ? identifier : ""
        },
    });
    
}

export function authenticateWithPassword(apikey: string, user_id:string, password: string) {


    return request({
        method: 'POST',
        url:`${getConfig().apiUrl}/users/login`,
        body:{user_id,
            password},
        headers: {
            'x-api-key': apikey,
            'Content-Type': 'application/json',
        },
    });
}


export function forgetPassword(apikey: string, user_id:string) {


    return request({
        method: 'POST',
        url:`${getConfig().apiUrl}/users/password/forgot`,
        body:{user_id},
        headers: {
            'x-api-key': apikey,
            'Content-Type': 'application/json',
        },
    });
}