// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { postOTPEmail } from '../api/postOTPEmail';
import { createForm } from './ui-helpers/createForm';
import { getProjectConfig } from '../helpers/projectConfig';
import { Defaults } from '../defaults';
import { createBrandFooter } from './ui-helpers/createBrandFooter';
import { createLoader } from './ui-helpers/createLoader';
import { createPhoneOTPVerificationPage } from './ui-helpers/createPhoneOTPVerificationPage';
import { addStyles } from '../helpers/common';
import '../styles/main.scss';

export async function phoneOTP(apikey:string,language:string,phone:string ,emailResponse:object,projectConfig): any {

	// Step 5: Add brand footer
	let promise=new Promise(function(resolve,reject){
		const callback=(response: any)=>{
          
		resolve(response)
	}
		if (emailResponse.state_id) {
			// show otp verification ui here
			createPhoneOTPVerificationPage({
				type: 'phone',
				state_id: emailResponse.state_id,
				value: phone,
				callback,
				apikey,
				language,
				projectConfig,
			});
		}
})
return promise

}
