import {registrationBegin} from "../../api/registrationBegin";
import {registrationComplete} from "../../api/registrationComplete";
import {create as createWebauthnCredential, PublicKeyCredentialWithAttestationJSON} from "@github/webauthn-json";

export interface Attestation extends PublicKeyCredentialWithAttestationJSON {
    transports: WebauthnTransports;
}
export type WebauthnTransports = Array<string>

export async function registerCredential(apikey: string, email: string, access_token: string): Promise<any> {
    const challenge = await registrationBegin(apikey, access_token);
    const state_id = challenge.state_id;

    let attestation: Attestation;
    try {
        attestation = await createWebauthnCredential(challenge) as Attestation;
        attestation.transports = attestation.response.transports;
    } catch (e) {
        console.log(e)
        throw e;
    }

    localStorage.setItem(`credId`, attestation.id);
    //api hit registration finish
    return new Promise(function (resolve, reject) {
        registrationComplete(apikey, access_token, state_id, attestation).then(async response => {
            if (response.code) {
                reject(response);
            } else {
                //await webauthnFlagUpdate(apikey, access_token)
                resolve(response)
            }
        }).catch((error) => {
            console.log(error)
            reject()
        })
    })
}
