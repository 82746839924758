import { request } from '../helpers/request';
import { getConfig } from '../config';

const url = `${getConfig().apiUrl}/users/phone/verify`;

export function verifyPhoneOTP(
	apikey: string,
	state_id: string,
	otp: string
): any {
	return request({
		method: 'POST',
		url,
		body:{
			otp,
			state_id,
		},
		headers: {
			'X-API-key': apikey,
		},
	});
}
