export const Defaults = {
	rootContainer: 'mojoauth-passwordless-form',
	loginFormContainer: 'mojoauth-login-container',
	postLoginContainerId: 'post-login-message',
	loginCardContainer: 'mojoauth-login-card',
	loginPasswordId : 'mojoauth-login-password',
	webauthnCardContainer: 'mojoauth-webauthn-container',
	loginFormId: 'mojoauth-login',
	brandFooterId: 'mojoauth-brand-footer',
	otpVerificationId: 'mojoauth-otp-verification',
	domainErrorMessage : 'domain-error-message',
	mojoauthWidgetTag : 'mojoauth-passwordless-widget'
};
