// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {ping} from '../api/ping';
import {createPostMagicLinkLoginPage} from './ui-helpers/createPostMagicLinkLoginPage';
import '../styles/main.scss';
import {signInWithMagicLink} from './singInWithMagicLink';
import {signInWithPhoneOTP} from './signInWithPhoneOTP';

export async function magicLink(apikey: string, redirect_url: string, language: string, email: string, emailResponse: object, projectConfig, isPhoneUI?: boolean): Promise<any> {
    //const options=this.options;
    return new Promise(function (resolve, reject) {
        const callback = (response: any) => {
            resolve(response)
        }
        // createLoader(projectConfig.data.btn_color);

        if (emailResponse.state_id) {

            createPostMagicLinkLoginPage(apikey, language, redirect_url, email, emailResponse.state_id, projectConfig);
            // ping api will take callback and will call it on successful response
            /**
             * If I can somehow make the code wait here unless I get the success response it will be great
             */
            ping(apikey, emailResponse.state_id, callback)

        } else if (emailResponse.code === 921) {

            // createPostMagicLinkLoginPage(apikey,email,emailResponse.state_id, projectConfig);
            isPhoneUI ?
                signInWithPhoneOTP(apikey, redirect_url, language, false, 'magiclink', false, true).then(response => {
                    resolve(response)
                })
                : signInWithMagicLink(apikey, redirect_url, language, true).then(response => resolve(response))

        } else if (emailResponse.code === 1003) {
            isPhoneUI ?
                signInWithPhoneOTP(apikey, redirect_url, language, false, 'magiclink', false, false, true).then(response => {
                    resolve(response)
                })
                : signInWithMagicLink(apikey, redirect_url, language, false, true).then(response => resolve(response))
        }
    })
}
