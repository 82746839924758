// eslint-disable-next-line @typescript-eslint/no-var-requires
import {signInWithEmailOTP} from './ui/signInWithEmailOTP';
import {verifyToken} from './api/verifyToken';
import {getPasskeyCredentials} from './api/passkeys/getPasskeyCredentials';
import {invalidateToken} from './api/invalidateToken';
import {signInWithMagicLink} from './ui/singInWithMagicLink'
import {enableWebauthn} from './ui/webauthn/enableWebauthn';
import {loginWebauthn} from './ui/webauthn/loginWebauthn';
import {signInWithStateID} from './ui/signInWithStateID'
import {signIn} from './ui/signIn';
import {signInWithWidget} from './ui/signInWithWidget';
import {registerPasskey} from "./ui/passkeys/registerPasskey";
import {patchPasskeyCredential, PasskeyCredentialUpdateRequest} from "./api/passkeys/patchPasskeyCredential";
import {deletePasskeyCredential} from "./api/passkeys/deletePasskeyCredential";
import {postSendMagicLink} from "./api/postSendMagicLink";
import {getCheckAuthStatus} from "./api/getCheckAuthStatus";
import {getPasskeyStatus} from "./api/passkeys/getPasskeyStatus";
import {loginWithPasskey} from "./ui/passkeys/loginPasskey";

interface MojoAuthProps {
    enable_webauthn?: boolean;
    language?: string;
    redirect_url?: string;
    email_input_id?: string;
}

export default class MojoAuth {
    signInWithMagicLink: () => void;
    signInWithEmailOTP: () => void;
    signIn: () => void;
    widgetSignIn: () => Promise<any>;
    signInWithStateID: () => Promise<any>;
    verifyToken: (token: string) => Promise<any>;
    invalidateToken: (token: string) => Promise<any>;
    enableWebauthn: (token: string) => Promise<any>;
    loginWebauthn: (email: string) => Promise<any>;
    getPasskeyCredentials: (token: string) => Promise<any>;
    registerPasskey: (email: string, token: string) => Promise<any>;
    updatePasskeyCredential: (id: string, token: string, body: PasskeyCredentialUpdateRequest) => Promise<any>;
    deletePasskeyCredential: (id: string, token: string) => Promise<any>;
    sendMagicLink: (id: string, token: string) => Promise<any>;
    checkAuthStatus: (stateId: string) => Promise<any>;
    getPasskeyStatus: (identifier: string) => Promise<any>;
    loginWithPasskey: (identifier: string) => Promise<any>;

    constructor(apikey: string, config?: MojoAuthProps) {
        if (!apikey) {
            console.error('apikey is required. Please pass valid apikey');
            return;
        }
        this.signInWithMagicLink = signInWithMagicLink.bind({apikey, config})
        this.signInWithEmailOTP = signInWithEmailOTP.bind({apikey, config})
        this.signIn = signIn.bind({apikey, config})
        this.widgetSignIn = signInWithWidget.bind({apikey, config})
        this.verifyToken = verifyToken.bind({apikey});
        this.invalidateToken = invalidateToken.bind({apikey});
        this.enableWebauthn = enableWebauthn.bind({apikey});
        this.loginWebauthn = loginWebauthn.bind({apikey});
        this.signInWithStateID = signInWithStateID.bind({apikey});

        this.getPasskeyCredentials = getPasskeyCredentials.bind({apikey});
        this.registerPasskey = registerPasskey.bind({apikey});
        this.updatePasskeyCredential = patchPasskeyCredential.bind({apikey});
        this.deletePasskeyCredential = deletePasskeyCredential.bind({apikey});
        this.sendMagicLink = postSendMagicLink.bind({apikey});
        this.checkAuthStatus = getCheckAuthStatus.bind({apikey});
        this.getPasskeyStatus = getPasskeyStatus.bind({apikey});
        this.loginWithPasskey = loginWithPasskey.bind({apikey});
    }
}
