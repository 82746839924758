export function addStyles(url: string): void {
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = url;
	document.head.appendChild(link);
}



function getMojoauthWidgetElement(host,identifier) {
    return host.shadowRoot.getElementById(identifier);
}

function getMojoauthWidgetElementValue(host,identifier) {
    return host.shadowRoot.getElementById(identifier).Value;
}


function showMojoauthWidgetElementValue(host,identifier) {
     host.shadowRoot.getElementById(identifier).style.display = "block";
    
}
function hideMojoauthWidgetElementValue(host,identifier) {
    host.shadowRoot.getElementById(identifier).style.display = "none";
   
}

export { getMojoauthWidgetElement,showMojoauthWidgetElementValue,hideMojoauthWidgetElementValue};