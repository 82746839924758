import {request} from '../../helpers/request';
import {getConfig} from '../../config';

const url = `${getConfig().apiUrl}/passkey/status`;

export function getPasskeyStatus(identifier: string): Promise<any> {
    const apikey = this.apikey;
    return request({
        method: 'GET',
        url,
        queryParams: {
            identifier,
        },
        headers: {
            'X-API-Key': apikey,
        },
    });
}