import {get as getWebauthnCredential, PublicKeyCredentialWithAssertionJSON} from "@github/webauthn-json";
import {getPasskeyLogin} from "../../api/passkeys/getPasskeyLogin";
import {postAssertPasskeyLogin} from "../../api/passkeys/postAssertPasskeyLogin";

export async function loginWithPasskey(identifier?: string): Promise<any> {
    const apikey = this.apikey;

    const challenge = await getPasskeyLogin(apikey, identifier);

    let assertion: PublicKeyCredentialWithAssertionJSON;
    let err;
    try {
        assertion = await getWebauthnCredential(challenge);
    } catch (e) {
        err = e;
    }

    //api hit login finish
    return new Promise(function (resolve, reject) {
        if (!err && assertion) {
            resolve(postAssertPasskeyLogin(apikey, identifier, challenge.state_id, assertion));
        } else {
            reject(err);
        }
    });
}