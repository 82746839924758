import { request } from '../../helpers/request';
import { getConfig } from '../../config';

const url = `${getConfig().apiUrl}/passkey/credentials`;

export interface PasskeyCredentialUpdateRequest {
    name: string
}

export function patchPasskeyCredential(id: string, token: string, body: PasskeyCredentialUpdateRequest): Promise<any> {
    const apikey = this.apikey;
    const requestUrl = `${url}/${id}`;
    return request({
        method: 'PATCH',
        url: requestUrl,
        body: body,
        headers: {
            'X-API-Key': apikey,
            Authorization: `Bearer ${token}`,
        },
    });
}
