import { request } from './request';
import { userConfig } from '../schema';
import { getConfig } from '../config';

const configUrl = `${getConfig().apiUrl}/projects/branding`;

const fetchConfig = (apikey?: string, language?: string) => {
  return request({
    method: 'GET',
    url: configUrl,
    queryParams: {
      language,
    },
    headers: {
      'X-API-Key': apikey,
    },
  });
};

export async function getProjectConfig(apikey?: string, language?: string): Promise<userConfig> {
  // if (promise) return promise;
  // promise = await fetchConfig(apikey, language);
  // if (promise) {
  // 	return promise;
  // } else {
  // 	throw new Error("Project Config promise not returned");
  // }
  const config = await fetchConfig(apikey, language);
  let email_password_schema = {
    "email_password": {
      "heading": "Sign in to mojoauth",
      "input": {
        "placeholder": "Enter your password",
        "label": "Password"
      },
      "or": "OR",
      "button": [
        {
          "text": "Continue"
        },
        {
          "text": "Login without Password"
        }
      ]
    }
  }
  config.data.localization = { ...config.data.localization, ...email_password_schema };
  return await config;
}