import {getConfig} from '../config';
import {request} from '../helpers/request';

const url = `${getConfig().apiUrl}/users/magiclink`;

/**
 *
 * @param apikey
 * @param email
 * @param language
 * @param redirect_url
 * @returns
 * @description post email to get a magic link on the user's email
 */
export function postEmail(apikey: string, email: string, language: string, redirect_url: string): any {
    return request({
        method: 'POST',
        url,
        queryParams: {
            language,
            redirect_url,
        },
        body: {
            email,
        },
        headers: {
            'X-API-Key': apikey,
        },
    });
}
