import { postEmail } from "../api/postEmail";
import { checkWebauthnStatus, webauthnRegistered } from "../api/webauthnCheck";
import { Defaults } from "../defaults";
import { addStyles } from "../helpers/common";
import { getProjectConfig } from "../helpers/projectConfig";
import { magicLink } from "./magicLink";
import { createBrandFooter } from "./ui-helpers/createBrandFooter";
import { createForm } from "./ui-helpers/createForm";
import { createLoader } from "./ui-helpers/createLoader";
import { webauthnRegister } from "./webauthn/registerPage";
import { authenticate } from "./webauthn/login";
import { authenticateWithPassword, authStatus, forgetPassword } from "../api/loginComplete";
const doWebauthnMagicLink = async function (apikey, projectConfig, enableWebauthn, email, redirect_url, language, resolve) {
    const loginCardContainer = document.getElementById(Defaults.loginCardContainer);
    while (loginCardContainer.firstChild) {
        loginCardContainer.removeChild(loginCardContainer.firstChild);
    }
    createLoader(projectConfig.data.btn_color);
    if (projectConfig.webauthn || enableWebauthn) {
        const check = await webauthnRegistered(apikey, email);

        // Check if user has already registered for webauthn credentials
        if (check.webauthn_registered) {
            //display here login page
            resolve(authenticate(apikey, email)
                .catch(async error => {
                    // Fallback if webauthn login fails
                    const emailResponse = await postEmail(apikey, email, language, redirect_url);
                    return magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig)
                }))
        }
        // Verify user credentials with magiclink
        else {
            const emailResponse = await postEmail(apikey, email, language, redirect_url);
            magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig)
                .then(response => {

                    //Check if the user has visited first time
                    if (check.webauthn_flag && emailResponse.state_id) {
                        // Display the webauthn register page

                        webauthnRegister(apikey, email, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                            .then(res => {

                                resolve(res)
                            })
                            .catch(error => {
                                console.log(error)
                                resolve(response)
                            })
                    }
                    //
                    else {
                        resolve(response)
                    }
                })
        }
    } else {
        const emailResponse = await postEmail(apikey, email, language, redirect_url);
        resolve(magicLink(apikey, redirect_url, language, email, emailResponse, projectConfig))
    }
}
const doWebauthnPasswordLogin = async function (apikey, userAuth, passwordField, projectConfig, enableWebauthn, email, redirect_url, language, resolve, reject) {
    // const loginCardContainer = document.getElementById(Defaults.loginCardContainer);
    // while (loginCardContainer.firstChild) {
    //     loginCardContainer.removeChild(loginCardContainer.firstChild);
    // }
    // createLoader(projectConfig.data.btn_color);
    if (projectConfig.webauthn || enableWebauthn) {
        const check = await webauthnRegistered(apikey, email);

        // Check if user has already registered for webauthn credentials
        if (check.webauthn_registered) {
            //display here login page
            return authenticate(apikey, email)
                .catch(async error => {
                    // Fallback if webauthn login fails
                    return await authenticateWithPassword(apikey, userAuth.user_id, passwordField.value);
                })
        }
        // Verify user credentials with magiclink
        else {
            const emailResponse = await postEmail(apikey, email, language, redirect_url);
            return authenticateWithPassword(apikey, userAuth.user_id, passwordField.value)
                .then(response => {
                    if (response.code) {
                        return response
                    }
                    //Check if the user has visited first time
                    if (check.webauthn_flag && emailResponse.state_id) {
                        // Display the webauthn register page

                        return webauthnRegister(apikey, email, check, projectConfig.data, response.oauth.access_token, emailResponse.state_id)
                            .then(res => {
                                return res
                            })
                            .catch(error => {
                                console.log(error)
                                return response
                            })
                    }
                    //
                    else {
                        return response
                    }
                }).catch(err => {
                    console.log(err)
                    return err
                })
        }
    } else {
        return authenticateWithPassword(apikey, userAuth.user_id, passwordField.value)

        ///resolve(authenticateWithPassword(apikey, userAuth.user_id, passwordField.value))
    }
}
export async function signInWithMagicLink(api_key?: string, redirecturl?: string, lang?: string, isDomainError?: boolean,
    isDisposableEmailError?: boolean, enableWebauthn?: boolean): Promise<any> {
    const apikey = this && this.apikey ? this.apikey : api_key;
    const language = this && this.config?.language ? this.config?.language : lang;
    const redirect_url = this && this.config?.redirect_url ? setHttp(this.config?.redirect_url) : redirecturl ? redirecturl : window.location.href;

    function setHttp(link) {
        if (link.search(/^http[s]?\:\/\//) == -1) {
            link = 'http://' + link;
        }
        return link;
    }

    let projectConfig;
    try {
        projectConfig = await getProjectConfig(apikey, language);
    } catch (e) {
        console.error(e.message);
        return;
    }

    //check for webauthn enabled in config, if present go for webauthn registeration here
    const rootContainerId = Defaults.rootContainer;
    const loginFormId = Defaults.loginFormContainer;
    // let promise;
    const container = document.getElementById(rootContainerId);
    if (!container) {
        console.error('HTML container not in the DOM');
        return;
    }
    // add new font on the root container and add styles

    container.style.fontFamily = projectConfig.data.font.family;
    if (projectConfig.data.font.url) {
        addStyles(projectConfig.data.font.url);
    }

    // Step 1: Create root login container

    const loginContainer = document.getElementById(loginFormId);
    if (loginContainer) {
        container.removeChild(loginContainer);
    }
    const loginFormContainer = document.createElement('div');
    loginFormContainer.id = loginFormId;

    // Step 2: Create main login container

    const loginMainContainer = document.createElement('div');
    loginMainContainer.classList.add("mj-main-js", "mj-wrapper")

    // Step 3: Create card login container

    const loginCardContainer = document.createElement('div')
    loginCardContainer.id = Defaults.loginCardContainer
    loginCardContainer.classList.add("mj-card-js")
    loginCardContainer.classList.add("padding-lg-js")
    loginCardContainer.style.setProperty('--css-direction', projectConfig.data.localization.direction)
    loginCardContainer.style.setProperty('--custom-color', projectConfig.data.btn_color)
    // Step 4: Create header login container

    const loginHeaderContainer = document.createElement('div');
    loginHeaderContainer.classList.add("header")

    // Step 5: Create logo container

    const loginLogoContainer = document.createElement('div')
    loginLogoContainer.classList.add('logo')
    projectConfig.data.brand_logo ? loginLogoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} alt="Company Name" />` : null;
    projectConfig.data.brand_logo ? loginHeaderContainer.appendChild(loginLogoContainer) : null;

    // Step 7: Create title container

    const loginTitleContainer = document.createElement('div')
    loginTitleContainer.classList.add("title")
    projectConfig.data.brand ? loginTitleContainer.innerHTML = `<h1>${projectConfig.data.localization.login.heading + " " + projectConfig.data.brand}</h1>` : null;

    projectConfig.data.brand ? loginHeaderContainer.appendChild(loginTitleContainer) : null;
    const loginSubTitleContainer = document.createElement('div')
    loginSubTitleContainer.classList.add("subtitle")
    loginSubTitleContainer.id = 'mojoauth-subtitle'
    loginHeaderContainer.appendChild(loginSubTitleContainer)
    loginCardContainer.appendChild(loginHeaderContainer)

    // Step 8: Add form

    const form = createForm(projectConfig.data, Defaults.loginFormId, apikey, projectConfig?.auth_config?.social,
        redirect_url, language, false, null, enableWebauthn || projectConfig.auth_config.webauthn)
    loginCardContainer.appendChild(form)

    if (isDomainError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'domain-error-message';
        errorMessage.textContent = `Email address entered is not whitelisted`;
        loginCardContainer.insertBefore(errorMessage, form)
    }
    if (isDisposableEmailError) {
        const errorMessage = document.createElement('p');
        errorMessage.className = 'error';
        errorMessage.id = 'domain-error-message';
        errorMessage.textContent = `The entered email address is either disposable or not allowed for login`;
        loginCardContainer.insertBefore(errorMessage, form)
    }
    //Step 9: Add optional social login

    // if(true){
    // const socialLoginContainer = document.createElement('div')
    // socialLoginContainer.classList.add('social-provider')
    // if(true){
    // 	const googleLoginContainer=document.createElement('div')
    // 	googleLoginContainer.classList.add('google')
    // 	googleLoginContainer.innerHTML=`<a href="#">
    //             <img src=${googleIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( googleLoginContainer )

    // }
    // if(true){
    // 	const facebookLoginContainer=document.createElement('div')
    // 	facebookLoginContainer.classList.add('google')
    // 	facebookLoginContainer.innerHTML=`<a href="#">
    //             <img src=${facebookIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( facebookLoginContainer )

    // }
    // if(true){
    // 	const appleLoginContainer=document.createElement('div')
    // 	appleLoginContainer.classList.add('google')
    // 	appleLoginContainer.innerHTML=`<a href="#">
    //             <img src=${appleIcon} alt="Google" />
    //           </a>`
    // 	socialLoginContainer.appendChild( appleLoginContainer )

    // }
    // }

    // Step 9: Add brand footer
    if (!projectConfig.data.white_label) {
        loginCardContainer.appendChild(createBrandFooter())
    }
    loginMainContainer.appendChild(loginCardContainer)
    loginFormContainer.appendChild(loginMainContainer)

    // loginFormContainer.classList.add(
    // 	'mojoauth--card',
    // 	'mojoauth--shadow',
    // 	'mojoauth--v-center'
    // );

    // // Step 2: Add brand logo container
    // const logoContainer = document.createElement('div');
    // logoContainer.classList.add('logo');
    // logoContainer.innerHTML = `<img src=${projectConfig.data.brand_logo} />`;
    // loginFormContainer.appendChild(logoContainer);

    // // Step 3: Add title and subtitle
    // const formTitle = document.createElement('div');
    // formTitle.classList.add('title');
    // formTitle.textContent = projectConfig.data.localization.login.heading + " " + projectConfig.data.brand ;
    // loginFormContainer.appendChild(formTitle);

    // const formSubTitle = document.createElement('div');
    // formSubTitle.classList.add('subtitle');
    // formSubTitle.textContent = projectConfig.data.localization.login.sub_heading;
    // loginFormContainer.appendChild(formSubTitle);

    // // Step 4: Add form
    // const form = createForm(projectConfig.data, Defaults.loginFormId);
    // loginFormContainer.appendChild(form);


    // // Step 5: Add brand footer
    // loginFormContainer.appendChild(createBrandFooter());

    container.appendChild(loginFormContainer);

    const promise = new Promise(function (resolve, reject) {
        if (enableWebauthn || projectConfig.auth_config.webauthn) {
            document.getElementById('mojoauth-passkey-signin-button').addEventListener("click", async () => {
                resolve(authenticate(apikey).catch(async err => {
                    console.log(err);
                }))
            });
        }
        form.onsubmit = async (e) => {
            e.preventDefault();
            createLoader(projectConfig.data.btn_color);

            const email = form.email.value
            const userAuth = await authStatus(apikey, email)
            //console.log(userAuth.password_auth);
            projectConfig.is_password_login_enabled = true;
            //  userAuth.password_auth = true;
            // Check if webauthn enabled from config or dashboard
            if (projectConfig.is_password_login_enabled && userAuth.password_auth) {
                const loginCardContainer = document.getElementById(Defaults.loginCardContainer);
                //const loginFormId = document.getElementById(Defaults.loginCardContainer);
                //create simple div
                const headerDiv = document.createElement('div')
                headerDiv.classList.add("header")
                headerDiv.innerHTML = `<div class="logo">
                  <img src="${projectConfig.data.brand_logo}" alt="logo name">
                  </div>
                  <div class="title">
                  <h1>${projectConfig.data.localization.email_password.heading}</h1>
                  </div>
                  <div class="subtitle" id="mojoauth-subtitle"></div>
                  `

                const formPassword = document.createElement('form')
                formPassword.classList.add('form');
                formPassword.id = Defaults.loginPasswordId;
                formPassword.innerHTML = `
                    <div class="input-control">
                       <label for="">${projectConfig.data.localization.email_password.input.label}</label>
                        <input type="password" id="email_password_field" required placeholder="${projectConfig.data.localization.email_password.input.placeholder}">
                    </div>
                    <div class="input-control">
                    <a id="forget-password" style="float: right; margin-bottom: 15px;" href="">Forgot Password?</a>
                     </div>
                    <div class="input-control">
                        <input type="submit"  id="login-with-password" class="btn btn-primary" value="${projectConfig.data.localization.email_password.button[0].text}">
                    </div>
                    <div class="separator" style="display:flex; justify-content:center; font-size: medium;">${projectConfig.data.localization.email_password.or}</div>
                    <div class="input-control">
                        <input type="submit" id="continue-without-password" class="btn btn-primary" value="${projectConfig.data.localization.email_password.button[1].text}">
                    </div>
                `;

                while (loginCardContainer.firstChild) {
                    loginCardContainer.removeChild(loginCardContainer.firstChild);
                }
                const loadingDiv = document.createElement('div');
                loadingDiv.id = 'mojoauth-loader'
                loadingDiv.classList.add('mojoauth--loader');
                loadingDiv.style.setProperty('--custom-color', projectConfig.data.btn_color)
                loadingDiv.style.display = 'none';
                loginCardContainer.appendChild(headerDiv);
                loginCardContainer.appendChild(formPassword);
                loginCardContainer.appendChild(loadingDiv);
                const passwordField = document.getElementById('email_password_field');
                passwordField.addEventListener("change", () => {
                    passwordField.style.borderColor = projectConfig.data.btn_color;
                    if(document.getElementById("alert_div"))document.getElementById("alert_div").remove();
                });

                const inputField = document.getElementById('email_password_field');


                document.getElementById('login-with-password').addEventListener("click", (e) => {
                    e.preventDefault();
                    const errorLabel = inputField.parentNode.querySelector('.error-label');
                    if (errorLabel) {
                        errorLabel.remove(); // Remove the error label if it exists
                    }
                    //alert("Login Successful");
                    //createLoader(projectConfig.data.btn_color);
                    if (passwordField.value.trim() !== '') {
                        loadingDiv.style.display = 'block';
                        formPassword.style.display = 'none';


                        doWebauthnPasswordLogin(apikey, userAuth, passwordField, projectConfig, enableWebauthn, email, redirect_url, language, resolve, reject).then(response => {
                            loadingDiv.style.display = 'none';
                            formPassword.style.display = 'block';
                            if (response.code) {
                                const alertDiv = document.createElement('div');
                                alertDiv.id = "alert_div";
                                const para = document.createElement('p')
                                para.textContent = response.description;
                                alertDiv.appendChild(para);
                                alertDiv.classList.add('mj-alert-error');
                                inputField.parentNode.insertBefore(alertDiv, inputField.nextSibling);
                            }
                            resolve(response)
                        }).catch(error => {
                            reject(error);
                        })
                    } else {
                        passwordField.style.borderColor = 'red';
                    }
                });
                document.getElementById('continue-without-password').addEventListener("click", async (e) => {
                    e.preventDefault();
                    await doWebauthnMagicLink(apikey, projectConfig, enableWebauthn, email, redirect_url, language, resolve)
                });
                document.getElementById('forget-password').addEventListener("click", async (e) => {
                    e.preventDefault();
                    loadingDiv.style.display = 'block';
                    formPassword.style.display = 'none';
                    await forgetPassword(apikey, userAuth.user_id).then(response => {
                        loadingDiv.style.display = 'none';
                        if (response.code) {
                            formPassword.style.display = 'block';
                            headerDiv.style.display = 'block';
                            const alertDiv = document.createElement('div');
                            alertDiv.id = "alert_div";
                            const para = document.createElement('p')
                            para.textContent = response.description;
                            alertDiv.appendChild(para);
                            alertDiv.classList.add('mj-alert-error');
                            inputField.parentNode.insertBefore(alertDiv, inputField.nextSibling);
                        }
                        else {
                            const forgetPasswordDiv = document.createElement('div');
                            forgetPasswordDiv.id = "forgetPasswordDiv";
                            forgetPasswordDiv.innerHTML = `<div class="mj-alert">
                            <p>Email has been sent.</p>
                                </div>
                                <div class="logo">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.cls-1{fill:#f8f8f8;}.cls-2{fill:#5e00cf;}.cls-3{fill:#5e00cf;}.cls-4{fill:#5e00cf;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect class="cls-1" width="150" height="150" rx="30"></rect><path class="cls-2" d="M75,85.83a15.1,15.1,0,0,1-6.67-1.33v4.67H80v-4A18,18,0,0,1,75,85.83Z"></path><path class="cls-3" d="M115,65c0-8.75-17.92-15.83-40-15.83S35,56.25,35,65a2.3,2.3,0,0,0,0,.37,3.54,3.54,0,0,0,0,.46V107.5a6.69,6.69,0,0,0,6.67,6.67h66.66A6.69,6.69,0,0,0,115,107.5V65.83a3.54,3.54,0,0,0,0-.46A2.3,2.3,0,0,0,115,65ZM80,89.17H68.33V84.5A15.1,15.1,0,0,0,75,85.83a18,18,0,0,0,5-.66Z"></path><path class="cls-1" d="M108.33,67.38V71L105,72.6l-6.67,3.28L74.73,87.5,51.67,76l-10-5V67.38A4.9,4.9,0,0,1,45,62.75a4.62,4.62,0,0,1,1.55-.25h56.9a4.62,4.62,0,0,1,1.55.25A4.9,4.9,0,0,1,108.33,67.38Z"></path><path class="cls-4" d="M93.17,35.83H56.83A11.86,11.86,0,0,0,45,47.67v25L51.67,76,74.73,87.5l23.6-11.62L105,72.6V47.67A11.86,11.86,0,0,0,93.17,35.83Z"></path><path class="cls-1" d="M98.33,46.42V75.88L74.73,87.5,51.67,76V46.42a3.93,3.93,0,0,1,3.91-3.92H94.42A3.93,3.93,0,0,1,98.33,46.42Z"></path><path class="cls-3" d="M80,85.17v4H68.33V84.5A15.1,15.1,0,0,0,75,85.83,18,18,0,0,0,80,85.17Z"></path><path class="cls-3" d="M74.39,48.05a13.5,13.5,0,1,0,13.5,13.5A13.51,13.51,0,0,0,74.39,48.05Zm7,9L72.69,67.41a1.06,1.06,0,0,1-.78.37h0a1,1,0,0,1-.77-.35l-3.74-4.15a1,1,0,0,1,1.52-1.43l0,0,2.94,3.27,7.95-9.48A1,1,0,0,1,81.41,57Z"></path></g></g></svg>
                                </div>
                                <div class="title">Check your mail</div>
                                <div class="subtitle">
                                You will receive a password recovery link at your email address in a few minutes
                                </div>
                          <input class="resend-link" id="resend" type="submit" name="submit" value="Resend Email" style="color: rgb(94, 0, 207);">`
                            loginCardContainer.removeChild(headerDiv);
                            loginCardContainer.removeChild(formPassword);
                            loginCardContainer.appendChild(forgetPasswordDiv);
                        } resolve(response)
                    }).catch(error => {
                        reject(error);
                    });
                });


            }
            else {
                await doWebauthnMagicLink(apikey, projectConfig, enableWebauthn, email, redirect_url, language, resolve)
            }


        }
    })
    return promise
}
