import {request} from '../helpers/request';
import {getConfig} from '../config';

const url = `${getConfig().apiUrl}/webauthn/login/initialize`;

export function loginBegin(apikey: string, identifier?: string): Promise<any> {
    return request({
        method: 'GET',
        credentials: "include",
        url,
        queryParams: {
            identifier: identifier ? identifier: "",
        },
        headers: {
            'X-API-Key': apikey,
        },
    });
}