import {userConfig} from '../../schema';
import {facebookIcon, googleIcon, appleIcon} from "../../helpers/svgIcons";
import {getConfig} from '../../config';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createForm(
    config: userConfig,
    id: string,
    apiKey: string,
    social?: any,
    redirect_url?: string,
    language?: string,
    phone?: boolean,
    auth?: string,
    shouldWebauthnLoginBtn?: boolean,
): HTMLFormElement {

    const {
        localization,
        btn_color,
        text_color
    } = config;
    const {
        login: {button, input, footer, or, phone_input},
        direction,
    } = localization;
    // const form = document.createElement('form');
    // form.id = id;
    // // Create input form group
    // const inputFormGroup = document.createElement('div');
    // inputFormGroup.classList.add('form-group');
    // const inputField = document.createElement('input');;
    // inputField.type = 'email';
    // inputField.required = true;
    // inputField.name = 'email';
    // inputField.id = 'mojoauth-passwordless-email';
    // inputField.placeholder = input.placeholder;
    // inputFormGroup.appendChild(inputField);
    // form.appendChild(inputFormGroup);

    // // create login button
    // const btnFormGroup = document.createElement('div');
    // btnFormGroup.classList.add('form-group');
    // const btn = document.createElement('input');
    // btn.type = 'submit';
    // btn.value = button.text;
    // // customize button background color
    // btn.style.backgroundColor = btn_color;
    // btnFormGroup.appendChild(btn);
    // form.appendChild(btnFormGroup);

    // // create privacy text
    // const privacyPara = document.createElement('p');
    // privacyPara.innerText = `${footer}`;
    // form.appendChild(privacyPara);

    const form = document.createElement('form');
    form.classList.add('form')
    form.id = id;

    //Email Form

    const inputFormGroup = document.createElement('div');
    inputFormGroup.classList.add('input-control');
    const labelField = document.createElement('label')
    labelField.htmlFor = "";
    labelField.textContent = phone ? phone_input.label : input.label;

    inputFormGroup.appendChild(labelField)

    const phoneInputField = document.createElement('input');
    if (phone) {
        const phoneInputGroup = document.createElement('div')
        phoneInputGroup.classList.add('input-group')

        // const phoneSelector = document.createElement('select')
        // phoneSelector.id='country-select';
        // const optionField1 = document.createElement('option')
        // optionField1.textContent = 'IN';
        // optionField1.value = '+91'
        // const optionField2 = document.createElement('option')
        // optionField2.textContent = 'CA';
        // optionField2.value= '+1'
        // const optionField3 = document.createElement('option')
        // optionField3.textContent = 'USA';
        // optionField3.value = '+1'

        // phoneSelector.appendChild(optionField1)
        // phoneSelector.appendChild(optionField2)
        // phoneSelector.appendChild(optionField3)

        phoneInputField.type = 'tel';
        phoneInputField.required = true;
        // phoneInputField.pattern='[0-9]{10}'
        // phoneInputField.title="Please enter your 10 digit phone Number"
        phoneInputField.name = 'phone';
        phoneInputField.id = 'mojoauth-passwordless-phone';
        phoneInputField.classList.add('phone-input')
        phoneInputField.style.setProperty('--css-direction', direction)
        phoneInputField.placeholder = phone_input.placeholder;

        const countryCodeDiv = document.createElement('div')
        countryCodeDiv.classList.add('country-code-group')
        const countryCode = document.createElement('div')
        countryCode.classList.add('country-code')
        countryCode.id = 'country-code-select'
        countryCode.textContent = '+1';

        // phoneInputGroup.appendChild(phoneSelector)
        countryCodeDiv.appendChild(countryCode)
        phoneInputGroup.appendChild(countryCodeDiv)
        phoneInputGroup.appendChild(phoneInputField);
        inputFormGroup.appendChild(phoneInputGroup)
        form.appendChild(inputFormGroup);
    } else {
        const inputField = document.createElement('input');
        inputField.type = 'email';
        inputField.required = true;
        inputField.name = 'email';
        inputField.id = 'mojoauth-passwordless-email';
        inputField.placeholder = input.placeholder;

        inputFormGroup.appendChild(inputField);
        form.appendChild(inputFormGroup);
    }

    const btnFormGroup = document.createElement('div');
    btnFormGroup.classList.add('input-control');
    const btn = document.createElement('input');
    btn.type = 'submit';
    btn.id = 'mojoauth-signin-button'
    btn.classList.add("btn")
    btn.classList.add("btn-primary")
    btn.value = button.text;
    btn.style.color = text_color;
    // customize button background color
    btn.style.backgroundColor = btn_color;
    btn.style.borderColor = btn_color

    btnFormGroup.appendChild(btn);
    form.appendChild(btnFormGroup);

    // Phone Form
    if(shouldWebauthnLoginBtn) {
        const separatorContainer = document.createElement('div')
        separatorContainer.classList.add('separator')
        const spanContainer = document.createElement('span')
        spanContainer.textContent = 'OR'
        separatorContainer.appendChild(spanContainer);
        form.appendChild(separatorContainer);

        const btnFormGroup = document.createElement('div');
        btnFormGroup.classList.add('input-control');
        const signinWithPasskeyBtn = document.createElement("BUTTON");
        signinWithPasskeyBtn.appendChild(document.createTextNode("Signin with a passkey"))
        signinWithPasskeyBtn.id = 'mojoauth-passkey-signin-button'
        signinWithPasskeyBtn.classList.add("btn")
        signinWithPasskeyBtn.style.color = text_color;
        signinWithPasskeyBtn.style.backgroundColor = btn_color;
        signinWithPasskeyBtn.style.borderColor = btn_color;
        signinWithPasskeyBtn.style.width = "100%";
        signinWithPasskeyBtn.style.borderRadius = "4px";

        btnFormGroup.appendChild(signinWithPasskeyBtn);
        form.appendChild(btnFormGroup);
    }

    if ((redirect_url && social && social.length) || auth) {
        const separatorContainer = document.createElement('div')
        separatorContainer.classList.add('separator')
        const spanContainer = document.createElement('span')
        spanContainer.textContent = 'OR'
        separatorContainer.appendChild(spanContainer);
        form.appendChild(separatorContainer)
        const phoneLoginButton = document.createElement('div')
        const phoneInputField = document.createElement('div')
        phoneInputField.classList.add('btn', 'btn-secondary')
        phoneInputField.id = 'feature-switch'
        if (auth && phone) {
            phoneLoginButton.classList.add('input-control', 'email')
            phoneInputField.textContent = input.label
            phoneLoginButton.appendChild(phoneInputField)
            form.appendChild(phoneLoginButton)
        } else if (auth && !phone) {
            phoneLoginButton.classList.add('input-control', 'phone')
            phoneInputField.textContent = phone_input.label;
            phoneLoginButton.appendChild(phoneInputField)
            form.appendChild(phoneLoginButton)
        }

        const socialLoginContainer = document.createElement('div')
        socialLoginContainer.classList.add('social-provider')

        if (redirect_url && social && social.length) {
            if (social.find((login) => login === 'google')) {
                const googleLoginContainer = document.createElement('div')
                googleLoginContainer.classList.add('google')
                googleLoginContainer.innerHTML = `<a href="${getConfig().apiUrl}/oauth/social?api_key=${apiKey}&provider=google&redirect_url=${redirect_url}">
				${googleIcon}
					</a>`
                socialLoginContainer.appendChild(googleLoginContainer)

            }
            if (social.find((login) => login === 'facebook')) {
                const facebookLoginContainer = document.createElement('div')
                facebookLoginContainer.classList.add('facebook')
                facebookLoginContainer.innerHTML = `<a href="${getConfig().apiUrl}/oauth/social?api_key=${apiKey}&provider=facebook&redirect_url=${redirect_url}">
						${facebookIcon}
					</a>`
                socialLoginContainer.appendChild(facebookLoginContainer)

            }
            if (social.find((login) => login === 'apple')) {
                const appleLoginContainer = document.createElement('div')
                appleLoginContainer.classList.add('apple')
                appleLoginContainer.innerHTML = `<a href="${getConfig().apiUrl}/oauth/social?api_key=${apiKey}&provider=apple&redirect_url=${redirect_url}">
					${appleIcon}
					</a>`
                socialLoginContainer.appendChild(appleLoginContainer)

            }
        }

        form.appendChild(socialLoginContainer)
    }

    return form;
}