import { getConfig } from '../config';
import { request } from '../helpers/request';

const url = `${getConfig().apiUrl}/users/emailotp/resend`;

/**
 *
 * @param apiKey
 * @param email
 * @returns
 * @description: post email to get otp on the user's email
 */
export function resendOTPEmail(apikey: string,language: string, state_id: string): any {
	return request({
		method: 'POST',
		url,
		queryParams:{
			language,
		},
		body: {
			state_id,
		},
		headers: {
			'X-API-Key': apikey,
		},
	});
}
