import {registrationBegin} from "../../api/registrationBegin";
import {registrationComplete} from "../../api/registrationComplete";
import {webauthnFlagUpdate} from "../../api/webauthnFlagUpdate";
import {create as createWebauthnCredential, PublicKeyCredentialWithAttestationJSON} from "@github/webauthn-json";

export async function enableWebauthn(access_token: string, api_key?: string): Promise<any> {
    const apikey = api_key ? api_key : this.apikey
    //api hit registration begin
    if (access_token === undefined || access_token === null) {
        access_token = ""
    }

    function bufferEncode(value) {
        return btoa(String.fromCharCode.apply(null, new Uint8Array(value)))
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }

    const challenge = await registrationBegin(apikey, access_token)
    const state_id = challenge.state_id;

    if (challenge.publicKey.user.name) {
        let attestation: PublicKeyCredentialWithAttestationJSON;
        try {
            attestation = await createWebauthnCredential(challenge);
        } catch (e) {
            console.log(e)
        }

        localStorage.setItem(`credId`, attestation.id);
        //api hit registration finish
        return new Promise(function (resolve, reject) {
            registrationComplete(apikey, access_token, state_id, attestation).then(async response => {
                if (response.code) {
                    reject(response);
                } else {
                    //await webauthnFlagUpdate(apikey, access_token)
                    resolve(response)
                }
            }).catch((error) => {
                console.log(error)
                reject()
            })
        })
    } else return null
}