import {
    create as createWebauthnCredential,
    PublicKeyCredentialWithAttestationJSON
} from "@github/webauthn-json";
import {getPasskeyRegistration} from "../../api/passkeys/getPasskeyRegistration";
import {postAssertPasskeyRegistration} from "../../api/passkeys/postAssertPasskeyRegistration";

export interface Attestation extends PublicKeyCredentialWithAttestationJSON {
    transports: WebauthnTransports;
}

export type WebauthnTransports = Array<string>

export async function registerPasskey(email?: string, access_token?: string): Promise<any> {
    const apikey = this.apikey;

    const challenge = await getPasskeyRegistration(apikey, access_token);

    let attestation: Attestation;
    let err;
    try {
        attestation = await createWebauthnCredential(challenge) as Attestation;
        attestation.transports = attestation.response.transports;
    } catch (e) {
        err = e;
    }

    return new Promise(function (resolve, reject) {
        if (err) {
            reject(err);
            return
        }
        postAssertPasskeyRegistration(apikey, access_token, challenge.state_id, attestation).then(async response => {
            if (response.code) {
                reject(response);
            } else {
                resolve(response);
            }
        }).catch((error) => {
            reject(error);
        })
    })
}
