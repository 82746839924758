import {registerCredential} from "./register";
import {authenticate} from "./login";
import {Defaults} from '../../defaults';
import {addStyles} from '../../helpers/common';
import {createBrandFooter} from '../ui-helpers/createBrandFooter';
import {createButton} from "../ui-helpers/createButton";
import {webauthnFlagUpdate} from "../../api/webauthnFlagUpdate";

interface checkObject {
    webauthn_registered: boolean,
    first_login: boolean,
    webauthn_flag: string
}

export async function webauthnRegister(apikey: string, email: string, check: checkObject, projectConfig, access_token: string, state_id: string): Promise<any> {

    //check for webauthn enabled in config, if present go for webauthn registeration here
    const rootContainerId = Defaults.rootContainer;
    const loginFormId = Defaults.loginFormContainer;
    const postLoginId = Defaults.postLoginContainerId;
    const otpVerificationId = Defaults.otpVerificationId;
    // let promise;

    const container = document.getElementById(rootContainerId);
    if (!container) {
        console.error('HTML container not in the DOM');
        return;
    }
    // add new font on the root container and add styles

    container.style.fontFamily = projectConfig.font.family;
    if (projectConfig.font.url) {
        addStyles(projectConfig.font.url);
    }

    const loginFormContainer = document.getElementById(loginFormId);
    if (loginFormContainer) {
        container.removeChild(loginFormContainer);
    }
    const postLoginContainer = document.getElementById(postLoginId);
    if (postLoginContainer) {
        container.removeChild(postLoginContainer);
    }
    const OTPVerificationContainer = document.getElementById(otpVerificationId);
    if (OTPVerificationContainer) {
        container.removeChild(OTPVerificationContainer);
    }
    // Step 1: Create root login container form
    const loginButtonContainer = document.createElement('div');
    loginButtonContainer.id = loginFormId;

    const loginMainContainer = document.createElement('div');
    loginMainContainer.classList.add("mj-main-js", "mj-wrapper")

    // Step 2: Create card login container

    const loginCardContainer = document.createElement('div')
    loginCardContainer.id = Defaults.loginCardContainer
    loginCardContainer.classList.add("mj-card-js")
    loginCardContainer.classList.add("padding-lg-js")
    loginCardContainer.style.setProperty('--css-direction', projectConfig.localization.direction)
    loginCardContainer.style.setProperty('--text-align-direction', projectConfig.localization.direction === 'rtl' ? 'right' : 'left')
    loginCardContainer.style.setProperty('--custom-color', projectConfig.btn_color)
    // Step 3: Create header login container

    const loginHeaderContainer = document.createElement('div');
    loginHeaderContainer.classList.add("header")

    // Step 5: Add title and subtitle
    const formTitle = document.createElement('div');
    formTitle.classList.add('title');
    formTitle.innerHTML = `<h1>${projectConfig.localization.webauthn_page.title_text}</h1>`;
    loginHeaderContainer.appendChild(formTitle);

    const formSubTitle1 = document.createElement('div');
    formSubTitle1.classList.add('webauthn-subtitle');
    formSubTitle1.innerHTML = `<h3>${projectConfig.localization.webauthn_page.sub_title_text_1}</h3>`;
    loginHeaderContainer.appendChild(formSubTitle1);

    const formSubTitle2 = document.createElement('div');
    formSubTitle2.classList.add('webauthn-subtitle');
    formSubTitle2.innerHTML = `<h3>${projectConfig.localization.webauthn_page.sub_title_text_2}</h3>`;
    loginHeaderContainer.appendChild(formSubTitle2);
    // Step 4: Add form
    // let buttonName=`${projectConfig.localization.webauthn_page.button1.text}`;
    const skipButton = document.createElement('input');
    skipButton.classList.add("skip-button")
    skipButton.id = "skip";
    skipButton.type = 'submit';
    skipButton.name = 'submit';
    skipButton.value = projectConfig.localization.webauthn_page.button1.text;
    skipButton.style.color = projectConfig.btn_color;
    // buttonName=`${projectConfig.localization.webauthn_page.button2.text}`;
    const continueButton = document.createElement('input');
    continueButton.classList.add("btn")
    continueButton.classList.add("btn-primary")
    continueButton.classList.add("continue-button")
    continueButton.style.setProperty('--custom-color', projectConfig.btn_color)
    continueButton.type = 'submit';
    continueButton.name = 'submit';
    continueButton.style.color = projectConfig.text_color
    continueButton.value = projectConfig.localization.webauthn_page.button2.text;
    loginCardContainer.appendChild(loginHeaderContainer)
    loginCardContainer.appendChild(continueButton);
    loginCardContainer.appendChild(skipButton);
    if (!projectConfig.white_label) {
        loginCardContainer.appendChild(createBrandFooter());
    }
    loginMainContainer.appendChild(loginCardContainer)
    loginButtonContainer.appendChild(loginMainContainer)
    container.appendChild(loginButtonContainer);

    // Step 5: Add brand footer


    let promise = new Promise(function (resolve, reject) {
        // if(check.first_login){
        skipButton.onclick = async (e) => {
            e.preventDefault();
            await webauthnFlagUpdate(apikey, access_token)
            reject()

        }
        // }
        // else{
        // 	skipButton.onsubmit = async (e) => {
        // 	e.preventDefault();
        // 	createLoader();
        // 	console.log("skipped")
        // 	const emailResponse = await postEmail(apikey, email);
        // 	resolve(signInWithMagicLink(apikey,email,check,emailResponse))

        // 	}
        // }
        continueButton.onclick = async (e) => {
            e.preventDefault();
            // if(check.webauthn_registered===false){
            resolve(registerCredential(apikey, email, access_token).catch(error => {
                console.log(error)
                return (webauthnRegister(apikey, email, check, projectConfig, access_token, state_id))
            }))
        }
        // else if(check.webauthn_registered===true){
        // resolve( authenticate(apikey,email) )
        // }
        // }
        // reject(webauthn(apikey,email,check,projectConfig))
    })
    return promise
}
