import {loginBegin} from "../../api/loginBegin";
import {loginComplete} from "../../api/loginComplete";
import {get as getWebauthnCredential, PublicKeyCredentialWithAssertionJSON,} from "@github/webauthn-json";

export const authenticate = async (apikey: string, identifier?: string) => {
    const challenge = await loginBegin(apikey, identifier);
    // get state id
    const state_id = challenge.state_id;

    let assertion: PublicKeyCredentialWithAssertionJSON;
    let err;
    try {
        assertion = await getWebauthnCredential(challenge);
    } catch (e) {
       console.log(e)
        err = e
    }

    console.log(assertion)

    //api hit login finish
    return new Promise(function (resolve, reject) {
        if (!err && assertion) {
            resolve(loginComplete(apikey, identifier, state_id, assertion));
        } else {
            reject(err);
        }
    })
};