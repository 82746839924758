import { request } from '../../helpers/request';
import { getConfig } from '../../config';

const url = `${getConfig().apiUrl}/passkey/credentials`;

export function getPasskeyCredentials(token: string): Promise<any> {
    const apikey = this.apikey;
    return request({
        method: 'GET',
        url,
        headers: {
            'X-API-Key': apikey,
            Authorization: `Bearer ${token}`,
        },
    });
}
