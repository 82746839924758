import {getConfig} from '../config';
import {request} from '../helpers/request';

const url = `${getConfig().apiUrl}/users/magiclink`;

/**
 *
 * @param email
 * @param language
 * @param redirect_url
 * @returns
 * @description post email to get a magic link on the user's email
 */
export function postSendMagicLink(email: string, language?: string, redirect_url?: string): any {
    const apikey = this.apikey;
    const queryParams = {};
    if (language) {
        queryParams['language'] = language
    }
    if (redirect_url) {
        queryParams['redirect_url'] = redirect_url
    }

    return request({
        method: 'POST',
        url,
        queryParams: queryParams,
        body: {email},
        headers: {'X-API-Key': apikey},
    });
}
