import { Defaults } from '../../defaults';
import { getProjectConfig } from '../../helpers/projectConfig';
import { createBrandFooter } from './createBrandFooter';

interface data {
	type: string;
	state_id: string;
	value: string;
	apikey: string;
	language: string;
	callback: any;
	projectConfig: any;
}
import '../../styles/main.scss';
import { verifyPhoneOTP } from '../../api/verifyOTPPhone';
import { resendOTPPhone } from '../../api/resendOTPPhone';

/**
 *
 * @param type what type of OTP verification, email or phone
 * @param value email or phone number which user entered
 * @returns void
 * @description This method is used to create OTP verification UI for both email and phone.
 */
export function createPhoneOTPVerificationPage({
	type,
	state_id,
	value,
	apikey,
	language,
	callback,
	projectConfig,
}: data): void {
	// const projectConfig = await getProjectConfig();
	const OTPSubmitHandler = async () => {
		if(document.getElementById('error-message')){
			const otpRow = document.querySelector('.mojoauth-otp-verification');
			otpRow.parentNode.removeChild(document.getElementById('error-message'));
		}
		// step 1: get all the pin values
		const mojoauthPinContainer = document.querySelector(
			'.mojoauth-otp-verification'
		);
		// step 2: get all the input boxes
		const inputElements = mojoauthPinContainer.querySelectorAll('input');
		// step 3: extract values from input boxes
		let otp = '';
		inputElements.forEach((input) => (otp += input.value));

		const response = await verifyPhoneOTP(apikey, state_id, otp);
		if (response.code) {
			const errorMessage = document.createElement('p');
			errorMessage.className = 'error';
			errorMessage.id='error-message';
			errorMessage.textContent = `${projectConfig.data.localization.magic_otp.error_text}`;
			const otpRow = document.querySelector('.mojoauth-otp-verification');
			otpRow.parentNode.insertBefore(errorMessage, otpRow.nextSibling);
			for (let i = 0; i < 6; i++) {
				(document.getElementById(`pin${i}`)as HTMLInputElement).value=""
				
			}
			document.getElementById('pin0').focus()

			return ;
		}
		if(response.authenticated)callback(response);
		
	};
	const pasteOTPHandler = (e) => {
		let otp = e.clipboardData.getData('text').trim()
		
		if(otp.length ===6 && !isNaN(otp)){
			
			let values = otp.split('');
		for (let i = 0; i < 6; i++) {
			(document.getElementById(`pin${i}`)as HTMLInputElement).value=values[i]
			document.getElementById(`pin${i}`).focus();
		}
		}
		
	}
	function formatTimeLeft(time) {
  // The largest round integer less than or equal to the result of time divided being by 60.
  const minutes = Math.floor(time / 60);
  
  // Seconds are the remainder of the time divided by 60 (modulus operator)
  let seconds= time % 60;
  // If the value of seconds is less than 10, then display seconds with a leading zero

  // The output in MM:SS format
  return `${(minutes).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:${(seconds).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`;
}
	function startTimer() {
		const TIME_LIMIT = 30;
		let timeLeft = TIME_LIMIT;
		let timerInterval = null;
		let timePassed = 0;
		timerInterval = setInterval(() => {
			
			// The amount of time passed increments by one
			timePassed = timePassed += 1;
			timeLeft = TIME_LIMIT - timePassed;
			// The time left label is updated
			if (timeLeft>0)
			{
				if(document.getElementById('resend')){
					(document.getElementById('resend')as any).value= ` ${projectConfig.data.localization.magic_otp.button.resend_again} ${formatTimeLeft(timeLeft)} `;
					(document.getElementById('resend')as any).disabled=true;
					document.getElementById('resend').style.color='grey';
					document.getElementById('resend').classList.add('disabled');
				}
			}
			else if(timeLeft<=0){
				if(document.getElementById('resend')){
					(document.getElementById('resend')as any).value= `${projectConfig.data.localization.magic_otp.button.resend}`;
					// (document.getElementById('resend')as any).value= `${projectConfig.data.localization.verification.button.resend}`;
					(document.getElementById('resend')as any).disabled=false;
					document.getElementById('resend').style.color=projectConfig.data.btn_color;
					document.getElementById('resend').classList.remove('disabled');
				}
				clearInterval(timerInterval)
			}
		}, 1000);

		}
	const ResendOTPHandler = async () =>{
		
		
		startTimer();
		if(document.getElementById('error-message')){
			const otpRow = document.querySelector('.mojoauth-otp-verification');
			otpRow.parentNode.removeChild(document.getElementById('error-message'));
		}
	 await resendOTPPhone(apikey, language, state_id);

	}

	const rootContainerId = Defaults.rootContainer;
	const loginFormId = Defaults.loginFormContainer;
	const otpVerificationId = Defaults.otpVerificationId;
	const container = document.getElementById(rootContainerId);
	if (!container) {
		console.error('HTML container not in the DOM');
		return;
	}

	const loginFormContainer = document.getElementById(loginFormId);
	if (loginFormContainer) {
		container.removeChild(loginFormContainer);
	}

	// create otp verification UI
	const OTPVerificationContainer = document.createElement('div');
	OTPVerificationContainer.id = otpVerificationId;
	// OTPVerificationContainer.classList.add(
	// 	'mojoauth--card',
	// 	'mojoauth--shadow',
	// 	'text-center',
	// 	'mojoauth--v-center'
	// );
	const loginMainContainer = document.createElement('div');
	loginMainContainer.classList.add("mj-main-js", "mj-wrapper")
	// Step 3: Create card login container form
	const loginCardContainer = document.createElement('div')
	loginCardContainer.id=Defaults.loginCardContainer
	loginCardContainer.classList.add("mj-card-js") 
	loginCardContainer.classList.add("padding-lg-js")
	loginCardContainer.style.setProperty('--custom-color', projectConfig.data.btn_color)
	// loginCardContainer.innerHTML = `<p>${projectConfig.data.localization.magic_otp.phone_text} ${value}</p>`;
	loginCardContainer.innerHTML = `
		<div class="title">${projectConfig.data.localization.magic_otp.title}</div>
		<div class="subtitle">
		${projectConfig.data.localization.magic_otp.subtitle} ${value}
		</div>
  `;
	// crate input pin rows
	const mojoauthPinForm = createPinBoxes(6);
	mojoauthPinForm.className = 'mojoauth-otp-verification';
	loginCardContainer.appendChild(mojoauthPinForm);
	// create submit button
	const submit = document.createElement('input');
	submit.type = 'button';
	submit.value = `${projectConfig.data.localization.magic_otp.button.text}`;
	submit.id = 'otp-submit-button';
	submit.classList.add("btn", "btn-primary")
	submit.style.backgroundColor = projectConfig.data.btn_color;
	submit.style.color = projectConfig.data.text_color
	loginCardContainer.appendChild(submit);
	// Add Resend button
	let buttonName=`${projectConfig.data.localization.magic_otp.button.resend}`;
	const ResendMailLink = document.createElement('input');
	ResendMailLink.classList.add('resend-link')
    ResendMailLink.id="resend";
	ResendMailLink.type='submit';
    ResendMailLink.name='submit';	
	ResendMailLink.value=buttonName;
	ResendMailLink.style.color = projectConfig.data.btn_color;
	loginCardContainer.appendChild(ResendMailLink);
	
	// Add brand footer
	if (!projectConfig.data.white_label) {
		loginCardContainer.appendChild(createBrandFooter());
	}

	loginMainContainer.appendChild(loginCardContainer)
	OTPVerificationContainer.appendChild(loginMainContainer)

	container.appendChild(OTPVerificationContainer);
	startTimer();
	// add submit handler
	document
		.getElementById('resend')
		.addEventListener('click', ResendOTPHandler);

	document
		.getElementById('otp-submit-button')
		.addEventListener('click', OTPSubmitHandler);

	document
		.addEventListener("keypress", function(e){
			if (e.code === "Enter") {
			   e.preventDefault();
			   OTPSubmitHandler();
			}
		});
		for (let i=0; i<6; i++){
			document.getElementById(`pin${i}`).addEventListener("paste", pasteOTPHandler)
		}
			
}

function setAutoFocus(e: KeyboardEvent, prev: string, next: string): void {
	const regex = new RegExp(/^\d+$/);
	if (!regex.test((<HTMLInputElement>e.target).value)) {
		// make value empty if non numeric value entered
		(<HTMLInputElement>e.target).value = '';
	}
	if (prev && (e.code === 'Backspace' || e.code === 'Delete')) {
		(<HTMLInputElement>e.target).blur();
		document.getElementById(prev).focus();
		return;
	}
	if (next && regex.test((<HTMLInputElement>e.target).value)) {
		document.getElementById(next).focus();
	}
}

function createPinBoxes(total: number): HTMLDivElement {
	const div = document.createElement('div');
	for (let i = 0; i < total; i++) {
		const inputGroup = document.createElement('div');
		inputGroup.className = 'form-group';
		const input = document.createElement('input');
		input.type = 'text';
		input.maxLength = 1;
		input.className = 'pin_input';
		input.id = `pin${i}`;
		input.inputMode = 'numeric';
		let next = `pin${i + 1}`;
		let prev = `pin${i - 1}`;
		input.addEventListener('keyup', (e: KeyboardEvent) => {
			if (i === total - 1) next = null;
			if (i === 0) prev = null;
			setAutoFocus(e, prev, next);
		});

		inputGroup.appendChild(input);
		div.appendChild(inputGroup);
	}
	return div;
}
