import { html } from "hybrids";
import { userConfig } from '../config';


function getStyle(width, height, button_border, button_color) {
    return  html`<style>
    .magicLink_widget {
                position: relative;
                width: ${width};
                font-family: "Inter", sans-serif;
                box-sizing: border-box;
            }

            .btn {
                background: ${button_color};
                border: 1px solid ${button_border};
                border-radius: 4px;
                width: ${width};
                height: ${height};
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                cursor: pointer;
                box-sizing: border-box;
            }

            .btn:hover .tooltip {
                display: block;
            }

            .btn img {
                margin-left: 2px;
            }


            .tooltip {
                background: #FFFFFF;
                box-shadow: -4px 4px 8px rgb(0 0 0 / 10%);
                border-radius: 4px;
                position: absolute;
                width: 90px;
                text-align: left;
                color: #4D5358;
                display: none;
            }


            .tooltip:before {
                content: '';
                top: calc(50% - 8px);
                left: 0;
                margin-left: calc(-1 * 16px);
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                border-right: 8px solid #fff;
                position: absolute;
                border-top: 8px solid transparent;
                width: 0;
                height: 0;
            }

            .tooltip.left:before {
                right: 0;
                margin-right: calc(-1 * 16px);
                border-bottom: 8px solid transparent;
                border-left: 8px solid #fff;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
                left: inherit;
            }

            .tooltip.top:before {
                top: inherit;
                left: 0;
                right: 0;
                bottom: -17px;
                margin: 0 auto;
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #fff;
            }

            .tooltip.bottom:before {
                top: -16px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                border-bottom: 8px solid #fff;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
            }

            .tooltip span {
                background: #F2F4F8;
                border-radius: 0px 0px 4px 4px;
                display: flex;
                padding: 4px 8px;
                gap: 2px;
                font-size: 12px;
            }

            .tooltip p {
                margin: 0;
                padding: 8px;
            }

            .tooltip.top {
                transform: translateY(-100%);
            }

            .tooltip.right {
                top: calc(50% - 35px);
                left: 62px;
            }

            .tooltip.bottom {
                transform: translateY(100%);
                box-shadow: -4px -2px 8px rgb(0 0 0 / 10%);
            }

            .tooltip.left {
                top: calc(50% - 35px);
                right: 62px;
                box-shadow: 2px 4px 8px rgb(0 0 0 / 10%);
            }

            .form_tooltip {
                background: #FFFFFF;
                box-shadow: 2px 4px 8px rgb(0 0 0 / 20%);
                border-radius: 4px;
                position: absolute;
                z-index: 2;
                width: 248px;
                top: calc(50% - 88px);
                right: 62px;
                text-align: center;
                color: #4D5358;
                padding: 20px 16px;
                box-sizing: border-box;
                min-height: 172px;
            }

            .form_tooltip.top {
                top: -18px;
                right: 0;
                left: 0%;
                transform: translate(-102px, -100%);
                margin: 0 auto;
            }

            .form_tooltip.right {
                top: calc(50% - 88px);
                left: inherit;
                right: -16px;
                transform: translateX(100%);
                box-shadow: -2px 4px 8px rgb(0 0 0 / 20%);
            }

            .form_tooltip.bottom {
                bottom: -18px;
                right: 0;
                left: 0%;
                transform: translate(-102px, calc(100% - 48px));
                margin: 0 auto;
                box-shadow: -2px 0px 8px rgb(0 0 0 / 20%);
            }
            .form_tooltip.bottom.bottom-reset{
                bottom: auto;
                transform: translate(-102px, calc(61% - 36px));
            }       
            .form_tooltip:before {
                content: '';
                top: calc(50% - 8px);
                right: 0;
                margin-right: calc(-1 * 16px);
                border-bottom: 8px solid transparent;
                border-left: 8px solid #fff;
                border-right: 8px solid transparent;
                position: absolute;
                border-top: 8px solid transparent;
                width: 0;
                height: 0;
            }


            .form_tooltip.right:before {
                right: 100%;
                margin-left: calc(-1 * 25px);
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                border-right: 8px solid #fff;
                border-top: 8px solid transparent;
                margin-right: 0;
            }

            .form_tooltip.top:before {
                top: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                border-bottom: 8px solid transparent;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid #fff;
            }

            .form_tooltip.bottom:before {
                top: -16px;
                left: 0;
                right: 0;
                bottom: 100%;
                margin: 0 auto 0px auto;
                border-bottom: 8px solid #fff;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-top: 8px solid transparent;
            }

            .form_tooltip span {
                background: #F2F4F8;
                border-radius: 0px 0px 4px 4px;
                display: flex;
                padding: 4px 8px;
                gap: 2px;
                font-size: 12px;
            }

            .form_tooltip h2 {
                margin-top: 0;
                font-weight: 600;
                font-size: 15px;
                margin-bottom: 8px;
                color: #121619;
            }

            .form_tooltip .icon {
                margin-bottom: 12px;
            }

            .form_tooltip p {
                margin: 0;
                font-size: 12px;
                color: #4d5358;
            }

            form {
                margin-top: 16px;
            }

            form input[type="email"] {
                width: 100%;
                border-radius: 3px;
                margin-bottom: 8px;
                border: 1px solid #4D5358;
                box-sizing: border-box;
                min-height: 28px;
                padding: 0 12px;
            }

            .mojoauth-password-phone{
                width: 100%;
                border-radius: 3px;
                margin-bottom: 8px;
                border: 1px solid #4D5358;
                box-sizing: border-box;
                min-height: 28px;
                padding: 0 12px;
            }
            .mojoauth-password-phone:focus{
                outline: 1px solid #6929c4;
            }
            form input[type="email"]:focus {
                outline: 1px solid #6929c4;
            }

            form input[type="submit"] {
                width: 100%;
                border-radius: 3px;
                background-color: #6929c4;
                color: #fff;
                border: 0;
                box-sizing: border-box;
                min-height: 28px;
                line-height: 1;
                cursor: pointer;
            }
            input[type="submit"] {
                width: 100%;
                border-radius: 3px;
                background-color: #6929c4;
                color: #fff;
                border: 0;
                box-sizing: border-box;
                min-height: 28px;
                line-height: 1;
                cursor: pointer;
            }

            .back {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 14px;
                color: #6929c4;
                cursor: pointer;
                margin-bottom: 10px;
            }

            .back:before {
                content: '';
                opacity: 1;
                width: 6px;
                height: 14px;
                display: block;
                margin-right: 7px;
                background: url("data:image/svg+xml,%0A%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.70711 0.292893C6.09763 0.683417 6.09763 1.31658 5.70711 1.70711L2.41421 5L5.70711 8.29289C6.09763 8.68342 6.09763 9.31658 5.70711 9.70711C5.31658 10.0976 4.68342 10.0976 4.29289 9.70711L0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893Z' fill='%236929c4'/%3E%3C/svg%3E%0A") no-repeat center center;
            }
            .mojoauth-otp-verification {
				display: flex;
				justify-content: space-evenly;
				margin: 8px 0px 24px 0px;
				
			}
            .mojoauth-otp-verification .pin_input {
					max-width: 16px;
					border: none;
					border-bottom: 2px solid #4d5358;
					width: 32px;
					height: 32px;
					margin: 4px 0px;
					text-align: center;
					color: #4d5358;
					font-size: 16px;
					background-color: #fff;
					
				}
                .mojoauth-otp-verification .pin_input:hover{
                    border-bottom: 2px solid #4d5358;
						outline: none;
                } 
                .mojoauth-otp-verification .pin_input:focus{
                    background-color: #fff;
                }

    .input-group {
        direction: ltr;
        display: flex;
        margin-bottom: 8px;
    }
 .input-group select {
	 width: 80px;
	 border-radius: 4px 0 0 4px;
}
 .input-group input {
	 margin-left: -1px;
	 border-radius: 0 4px 4px 0;

}
 .input-group .country-code-group {
	 position: absolute;
	/* overflow: auto;
	 */
    padding-top: 4px;
	 z-index: 10;
	 margin-left: 16%;
}
 @media (max-width: 767px) {
	 .input-group .country-code-group {
		 margin-left: 17%;
	}
}
 .input-group .country-code-group .country-code {
	 color: #878d96;
	 font-size: small;
	/* align-content: center;
	 */
	 width: 50px;
	 display: flex;
	/* margin: 0 auto;
	 */
	 justify-content: center;
}
 
.iti {
	position: relative;
	display: inline-block;
	width: 80%;
	left: 20%;
}
.iti * {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}
.iti__hide {
	display: none;
}
.iti__v-hide {
	visibility: hidden;
}
.iti input,
.iti input[type='text'],
.iti input[type='tel'] {
	position: relative;
	z-index: 0;
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	padding-right: 36px;
	margin-right: 0;
	width: 100%;
}
.iti__flag-container {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	padding: 1px;
}
.iti__selected-flag {
	right: 100%;
	bottom: 2%;
	z-index: 1;
	border: 1px solid lightgray;
	border-right: none;
	position: relative;
	display: flex;
    justify-content: space-around;
	align-items: center;
	height: 104%;
	
}
.iti__arrow {
	
	width: 0;
	height: 0;
	border-left: 3px solid transparent;
	border-right: 3px solid transparent;
	border-top: 4px solid #555;
}
.iti__arrow--up {
	border-top: none;
	border-bottom: 4px solid #555;
}
.iti__country-list {
	width: 210px;
	left: -94%;
	position: absolute;
	z-index: 2;
	list-style: none;
	text-align: left;
	padding: 0;
	margin: 0 0 0 -1px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	background-color: white;
	border: 1px solid #ccc;
	white-space: nowrap;
	max-height: 200px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}
.iti__country-list--dropup {
	bottom: 100%;
	margin-bottom: -1px;
}
@media (max-width: 500px) {
	.iti__country-list {
		white-space: normal;
	}
}
.iti__flag-box {
	display: inline-block;
	width: 20px;
}
.iti__divider {
	padding-bottom: 5px;
	margin-bottom: 5px;
	border-bottom: 1px solid #ccc;
}
.iti__country {
	padding: 5px 10px;
	outline: none;
    justify-content: space-between;
    display: flex;
    align-items: center;
}
.iti__dial-code {
	color: #999;
}
.iti__country.iti__highlight {
	background-color: rgba(0, 0, 0, 0.05);
}
.iti__flag-box,
.iti__country-name,
.iti__dial-code {
	vertical-align: middle;
}
.iti__flag-box,
.iti__country-name {
	margin-right: 6px;
}
.iti--allow-dropdown input,
.iti--allow-dropdown input[type='text'],
.iti--allow-dropdown input[type='tel'],
.iti--separate-dial-code input,
.iti--separate-dial-code input[type='text'],
.iti--separate-dial-code input[type='tel'] {
	padding-right: 6px;
	padding-left: 52px;
	margin-left: 0;
}
.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
	right: 74%;
	left: 0;
}
.iti--allow-dropdown .iti__flag-container:hover {
	cursor: pointer;
}
.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
	background-color: rgba(0, 0, 0, 0.05);
}
.iti--allow-dropdown input[disabled] + .iti__flag-container:hover,
.iti--allow-dropdown input[readonly] + .iti__flag-container:hover {
	cursor: default;
}
.iti--allow-dropdown
	input[disabled]
	+ .iti__flag-container:hover
	.iti__selected-flag,
.iti--allow-dropdown
	input[readonly]
	+ .iti__flag-container:hover
	.iti__selected-flag {
	background-color: transparent;
}
.iti--separate-dial-code .iti__selected-flag {
	background-color: rgba(0, 0, 0, 0.05);
}
.iti--separate-dial-code .iti__selected-dial-code {
	margin-left: 6px;
}
.iti--container {
	position: absolute;
	top: -1000px;
	left: -1000px;
	z-index: 1060;
	padding: 1px;
}
.iti--container:hover {
	cursor: pointer;
}

.iti-mobile .iti--container {
	top: 30px;
	bottom: 30px;
	left: 30px;
	right: 30px;
	position: fixed;
}

.iti-mobile .iti__country-list {
	max-height: 100%;
	width: 100%;
	left: 4%;
}

.iti-mobile .iti__country {
	padding: 10px 10px;
	line-height: 1.5em;
}

.iti__flag {
	width: 20px;
}
.iti__flag.iti__be {
	width: 18px;
}
.iti__flag.iti__ch {
	width: 15px;
}
.iti__flag.iti__mc {
	width: 19px;
}
.iti__flag.iti__ne {
	width: 18px;
}
.iti__flag.iti__np {
	width: 13px;
}
.iti__flag.iti__va {
	width: 15px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-size: 5652px 15px;
	}
}
.iti__flag.iti__ac {
	height: 10px;
	background-position: 0px 0px;
}
.iti__flag.iti__ad {
	height: 14px;
	background-position: -22px 0px;
}
.iti__flag.iti__ae {
	height: 10px;
	background-position: -44px 0px;
}
.iti__flag.iti__af {
	height: 14px;
	background-position: -66px 0px;
}
.iti__flag.iti__ag {
	height: 14px;
	background-position: -88px 0px;
}
.iti__flag.iti__ai {
	height: 10px;
	background-position: -110px 0px;
}
.iti__flag.iti__al {
	height: 15px;
	background-position: -132px 0px;
}
.iti__flag.iti__am {
	height: 10px;
	background-position: -154px 0px;
}
.iti__flag.iti__ao {
	height: 14px;
	background-position: -176px 0px;
}
.iti__flag.iti__aq {
	height: 14px;
	background-position: -198px 0px;
}
.iti__flag.iti__ar {
	height: 13px;
	background-position: -220px 0px;
}
.iti__flag.iti__as {
	height: 10px;
	background-position: -242px 0px;
}
.iti__flag.iti__at {
	height: 14px;
	background-position: -264px 0px;
}
.iti__flag.iti__au {
	height: 10px;
	background-position: -286px 0px;
}
.iti__flag.iti__aw {
	height: 14px;
	background-position: -308px 0px;
}
.iti__flag.iti__ax {
	height: 13px;
	background-position: -330px 0px;
}
.iti__flag.iti__az {
	height: 10px;
	background-position: -352px 0px;
}
.iti__flag.iti__ba {
	height: 10px;
	background-position: -374px 0px;
}
.iti__flag.iti__bb {
	height: 14px;
	background-position: -396px 0px;
}
.iti__flag.iti__bd {
	height: 12px;
	background-position: -418px 0px;
}
.iti__flag.iti__be {
	height: 15px;
	background-position: -440px 0px;
}
.iti__flag.iti__bf {
	height: 14px;
	background-position: -460px 0px;
}
.iti__flag.iti__bg {
	height: 12px;
	background-position: -482px 0px;
}
.iti__flag.iti__bh {
	height: 12px;
	background-position: -504px 0px;
}
.iti__flag.iti__bi {
	height: 12px;
	background-position: -526px 0px;
}
.iti__flag.iti__bj {
	height: 14px;
	background-position: -548px 0px;
}
.iti__flag.iti__bl {
	height: 14px;
	background-position: -570px 0px;
}
.iti__flag.iti__bm {
	height: 10px;
	background-position: -592px 0px;
}
.iti__flag.iti__bn {
	height: 10px;
	background-position: -614px 0px;
}
.iti__flag.iti__bo {
	height: 14px;
	background-position: -636px 0px;
}
.iti__flag.iti__bq {
	height: 14px;
	background-position: -658px 0px;
}
.iti__flag.iti__br {
	height: 14px;
	background-position: -680px 0px;
}
.iti__flag.iti__bs {
	height: 10px;
	background-position: -702px 0px;
}
.iti__flag.iti__bt {
	height: 14px;
	background-position: -724px 0px;
}
.iti__flag.iti__bv {
	height: 15px;
	background-position: -746px 0px;
}
.iti__flag.iti__bw {
	height: 14px;
	background-position: -768px 0px;
}
.iti__flag.iti__by {
	height: 10px;
	background-position: -790px 0px;
}
.iti__flag.iti__bz {
	height: 14px;
	background-position: -812px 0px;
}
.iti__flag.iti__ca {
	height: 10px;
	background-position: -834px 0px;
}
.iti__flag.iti__cc {
	height: 10px;
	background-position: -856px 0px;
}
.iti__flag.iti__cd {
	height: 15px;
	background-position: -878px 0px;
}
.iti__flag.iti__cf {
	height: 14px;
	background-position: -900px 0px;
}
.iti__flag.iti__cg {
	height: 14px;
	background-position: -922px 0px;
}
.iti__flag.iti__ch {
	height: 15px;
	background-position: -944px 0px;
}
.iti__flag.iti__ci {
	height: 14px;
	background-position: -961px 0px;
}
.iti__flag.iti__ck {
	height: 10px;
	background-position: -983px 0px;
}
.iti__flag.iti__cl {
	height: 14px;
	background-position: -1005px 0px;
}
.iti__flag.iti__cm {
	height: 14px;
	background-position: -1027px 0px;
}
.iti__flag.iti__cn {
	height: 14px;
	background-position: -1049px 0px;
}
.iti__flag.iti__co {
	height: 14px;
	background-position: -1071px 0px;
}
.iti__flag.iti__cp {
	height: 14px;
	background-position: -1093px 0px;
}
.iti__flag.iti__cr {
	height: 12px;
	background-position: -1115px 0px;
}
.iti__flag.iti__cu {
	height: 10px;
	background-position: -1137px 0px;
}
.iti__flag.iti__cv {
	height: 12px;
	background-position: -1159px 0px;
}
.iti__flag.iti__cw {
	height: 14px;
	background-position: -1181px 0px;
}
.iti__flag.iti__cx {
	height: 10px;
	background-position: -1203px 0px;
}
.iti__flag.iti__cy {
	height: 14px;
	background-position: -1225px 0px;
}
.iti__flag.iti__cz {
	height: 14px;
	background-position: -1247px 0px;
}
.iti__flag.iti__de {
	height: 12px;
	background-position: -1269px 0px;
}
.iti__flag.iti__dg {
	height: 10px;
	background-position: -1291px 0px;
}
.iti__flag.iti__dj {
	height: 14px;
	background-position: -1313px 0px;
}
.iti__flag.iti__dk {
	height: 15px;
	background-position: -1335px 0px;
}
.iti__flag.iti__dm {
	height: 10px;
	background-position: -1357px 0px;
}
.iti__flag.iti__do {
	height: 14px;
	background-position: -1379px 0px;
}
.iti__flag.iti__dz {
	height: 14px;
	background-position: -1401px 0px;
}
.iti__flag.iti__ea {
	height: 14px;
	background-position: -1423px 0px;
}
.iti__flag.iti__ec {
	height: 14px;
	background-position: -1445px 0px;
}
.iti__flag.iti__ee {
	height: 13px;
	background-position: -1467px 0px;
}
.iti__flag.iti__eg {
	height: 14px;
	background-position: -1489px 0px;
}
.iti__flag.iti__eh {
	height: 10px;
	background-position: -1511px 0px;
}
.iti__flag.iti__er {
	height: 10px;
	background-position: -1533px 0px;
}
.iti__flag.iti__es {
	height: 14px;
	background-position: -1555px 0px;
}
.iti__flag.iti__et {
	height: 10px;
	background-position: -1577px 0px;
}
.iti__flag.iti__eu {
	height: 14px;
	background-position: -1599px 0px;
}
.iti__flag.iti__fi {
	height: 12px;
	background-position: -1621px 0px;
}
.iti__flag.iti__fj {
	height: 10px;
	background-position: -1643px 0px;
}
.iti__flag.iti__fk {
	height: 10px;
	background-position: -1665px 0px;
}
.iti__flag.iti__fm {
	height: 11px;
	background-position: -1687px 0px;
}
.iti__flag.iti__fo {
	height: 15px;
	background-position: -1709px 0px;
}
.iti__flag.iti__fr {
	height: 14px;
	background-position: -1731px 0px;
}
.iti__flag.iti__ga {
	height: 15px;
	background-position: -1753px 0px;
}
.iti__flag.iti__gb {
	height: 10px;
	background-position: -1775px 0px;
}
.iti__flag.iti__gd {
	height: 12px;
	background-position: -1797px 0px;
}
.iti__flag.iti__ge {
	height: 14px;
	background-position: -1819px 0px;
}
.iti__flag.iti__gf {
	height: 14px;
	background-position: -1841px 0px;
}
.iti__flag.iti__gg {
	height: 14px;
	background-position: -1863px 0px;
}
.iti__flag.iti__gh {
	height: 14px;
	background-position: -1885px 0px;
}
.iti__flag.iti__gi {
	height: 10px;
	background-position: -1907px 0px;
}
.iti__flag.iti__gl {
	height: 14px;
	background-position: -1929px 0px;
}
.iti__flag.iti__gm {
	height: 14px;
	background-position: -1951px 0px;
}
.iti__flag.iti__gn {
	height: 14px;
	background-position: -1973px 0px;
}
.iti__flag.iti__gp {
	height: 14px;
	background-position: -1995px 0px;
}
.iti__flag.iti__gq {
	height: 14px;
	background-position: -2017px 0px;
}
.iti__flag.iti__gr {
	height: 14px;
	background-position: -2039px 0px;
}
.iti__flag.iti__gs {
	height: 10px;
	background-position: -2061px 0px;
}
.iti__flag.iti__gt {
	height: 13px;
	background-position: -2083px 0px;
}
.iti__flag.iti__gu {
	height: 11px;
	background-position: -2105px 0px;
}
.iti__flag.iti__gw {
	height: 10px;
	background-position: -2127px 0px;
}
.iti__flag.iti__gy {
	height: 12px;
	background-position: -2149px 0px;
}
.iti__flag.iti__hk {
	height: 14px;
	background-position: -2171px 0px;
}
.iti__flag.iti__hm {
	height: 10px;
	background-position: -2193px 0px;
}
.iti__flag.iti__hn {
	height: 10px;
	background-position: -2215px 0px;
}
.iti__flag.iti__hr {
	height: 10px;
	background-position: -2237px 0px;
}
.iti__flag.iti__ht {
	height: 12px;
	background-position: -2259px 0px;
}
.iti__flag.iti__hu {
	height: 10px;
	background-position: -2281px 0px;
}
.iti__flag.iti__ic {
	height: 14px;
	background-position: -2303px 0px;
}
.iti__flag.iti__id {
	height: 14px;
	background-position: -2325px 0px;
}
.iti__flag.iti__ie {
	height: 10px;
	background-position: -2347px 0px;
}
.iti__flag.iti__il {
	height: 15px;
	background-position: -2369px 0px;
}
.iti__flag.iti__im {
	height: 10px;
	background-position: -2391px 0px;
}
.iti__flag.iti__in {
	height: 14px;
	background-position: -2413px 0px;
}
.iti__flag.iti__io {
	height: 10px;
	background-position: -2435px 0px;
}
.iti__flag.iti__iq {
	height: 14px;
	background-position: -2457px 0px;
}
.iti__flag.iti__ir {
	height: 12px;
	background-position: -2479px 0px;
}
.iti__flag.iti__is {
	height: 15px;
	background-position: -2501px 0px;
}
.iti__flag.iti__it {
	height: 14px;
	background-position: -2523px 0px;
}
.iti__flag.iti__je {
	height: 12px;
	background-position: -2545px 0px;
}
.iti__flag.iti__jm {
	height: 10px;
	background-position: -2567px 0px;
}
.iti__flag.iti__jo {
	height: 10px;
	background-position: -2589px 0px;
}
.iti__flag.iti__jp {
	height: 14px;
	background-position: -2611px 0px;
}
.iti__flag.iti__ke {
	height: 14px;
	background-position: -2633px 0px;
}
.iti__flag.iti__kg {
	height: 12px;
	background-position: -2655px 0px;
}
.iti__flag.iti__kh {
	height: 13px;
	background-position: -2677px 0px;
}
.iti__flag.iti__ki {
	height: 10px;
	background-position: -2699px 0px;
}
.iti__flag.iti__km {
	height: 12px;
	background-position: -2721px 0px;
}
.iti__flag.iti__kn {
	height: 14px;
	background-position: -2743px 0px;
}
.iti__flag.iti__kp {
	height: 10px;
	background-position: -2765px 0px;
}
.iti__flag.iti__kr {
	height: 14px;
	background-position: -2787px 0px;
}
.iti__flag.iti__kw {
	height: 10px;
	background-position: -2809px 0px;
}
.iti__flag.iti__ky {
	height: 10px;
	background-position: -2831px 0px;
}
.iti__flag.iti__kz {
	height: 10px;
	background-position: -2853px 0px;
}
.iti__flag.iti__la {
	height: 14px;
	background-position: -2875px 0px;
}
.iti__flag.iti__lb {
	height: 14px;
	background-position: -2897px 0px;
}
.iti__flag.iti__lc {
	height: 10px;
	background-position: -2919px 0px;
}
.iti__flag.iti__li {
	height: 12px;
	background-position: -2941px 0px;
}
.iti__flag.iti__lk {
	height: 10px;
	background-position: -2963px 0px;
}
.iti__flag.iti__lr {
	height: 11px;
	background-position: -2985px 0px;
}
.iti__flag.iti__ls {
	height: 14px;
	background-position: -3007px 0px;
}
.iti__flag.iti__lt {
	height: 12px;
	background-position: -3029px 0px;
}
.iti__flag.iti__lu {
	height: 12px;
	background-position: -3051px 0px;
}
.iti__flag.iti__lv {
	height: 10px;
	background-position: -3073px 0px;
}
.iti__flag.iti__ly {
	height: 10px;
	background-position: -3095px 0px;
}
.iti__flag.iti__ma {
	height: 14px;
	background-position: -3117px 0px;
}
.iti__flag.iti__mc {
	height: 15px;
	background-position: -3139px 0px;
}
.iti__flag.iti__md {
	height: 10px;
	background-position: -3160px 0px;
}
.iti__flag.iti__me {
	height: 10px;
	background-position: -3182px 0px;
}
.iti__flag.iti__mf {
	height: 14px;
	background-position: -3204px 0px;
}
.iti__flag.iti__mg {
	height: 14px;
	background-position: -3226px 0px;
}
.iti__flag.iti__mh {
	height: 11px;
	background-position: -3248px 0px;
}
.iti__flag.iti__mk {
	height: 10px;
	background-position: -3270px 0px;
}
.iti__flag.iti__ml {
	height: 14px;
	background-position: -3292px 0px;
}
.iti__flag.iti__mm {
	height: 14px;
	background-position: -3314px 0px;
}
.iti__flag.iti__mn {
	height: 10px;
	background-position: -3336px 0px;
}
.iti__flag.iti__mo {
	height: 14px;
	background-position: -3358px 0px;
}
.iti__flag.iti__mp {
	height: 10px;
	background-position: -3380px 0px;
}
.iti__flag.iti__mq {
	height: 14px;
	background-position: -3402px 0px;
}
.iti__flag.iti__mr {
	height: 14px;
	background-position: -3424px 0px;
}
.iti__flag.iti__ms {
	height: 10px;
	background-position: -3446px 0px;
}
.iti__flag.iti__mt {
	height: 14px;
	background-position: -3468px 0px;
}
.iti__flag.iti__mu {
	height: 14px;
	background-position: -3490px 0px;
}
.iti__flag.iti__mv {
	height: 14px;
	background-position: -3512px 0px;
}
.iti__flag.iti__mw {
	height: 14px;
	background-position: -3534px 0px;
}
.iti__flag.iti__mx {
	height: 12px;
	background-position: -3556px 0px;
}
.iti__flag.iti__my {
	height: 10px;
	background-position: -3578px 0px;
}
.iti__flag.iti__mz {
	height: 14px;
	background-position: -3600px 0px;
}
.iti__flag.iti__na {
	height: 14px;
	background-position: -3622px 0px;
}
.iti__flag.iti__nc {
	height: 10px;
	background-position: -3644px 0px;
}
.iti__flag.iti__ne {
	height: 15px;
	background-position: -3666px 0px;
}
.iti__flag.iti__nf {
	height: 10px;
	background-position: -3686px 0px;
}
.iti__flag.iti__ng {
	height: 10px;
	background-position: -3708px 0px;
}
.iti__flag.iti__ni {
	height: 12px;
	background-position: -3730px 0px;
}
.iti__flag.iti__nl {
	height: 14px;
	background-position: -3752px 0px;
}
.iti__flag.iti__no {
	height: 15px;
	background-position: -3774px 0px;
}
.iti__flag.iti__np {
	height: 15px;
	background-position: -3796px 0px;
}
.iti__flag.iti__nr {
	height: 10px;
	background-position: -3811px 0px;
}
.iti__flag.iti__nu {
	height: 10px;
	background-position: -3833px 0px;
}
.iti__flag.iti__nz {
	height: 10px;
	background-position: -3855px 0px;
}
.iti__flag.iti__om {
	height: 10px;
	background-position: -3877px 0px;
}
.iti__flag.iti__pa {
	height: 14px;
	background-position: -3899px 0px;
}
.iti__flag.iti__pe {
	height: 14px;
	background-position: -3921px 0px;
}
.iti__flag.iti__pf {
	height: 14px;
	background-position: -3943px 0px;
}
.iti__flag.iti__pg {
	height: 15px;
	background-position: -3965px 0px;
}
.iti__flag.iti__ph {
	height: 10px;
	background-position: -3987px 0px;
}
.iti__flag.iti__pk {
	height: 14px;
	background-position: -4009px 0px;
}
.iti__flag.iti__pl {
	height: 13px;
	background-position: -4031px 0px;
}
.iti__flag.iti__pm {
	height: 14px;
	background-position: -4053px 0px;
}
.iti__flag.iti__pn {
	height: 10px;
	background-position: -4075px 0px;
}
.iti__flag.iti__pr {
	height: 14px;
	background-position: -4097px 0px;
}
.iti__flag.iti__ps {
	height: 10px;
	background-position: -4119px 0px;
}
.iti__flag.iti__pt {
	height: 14px;
	background-position: -4141px 0px;
}
.iti__flag.iti__pw {
	height: 13px;
	background-position: -4163px 0px;
}
.iti__flag.iti__py {
	height: 11px;
	background-position: -4185px 0px;
}
.iti__flag.iti__qa {
	height: 8px;
	background-position: -4207px 0px;
}
.iti__flag.iti__re {
	height: 14px;
	background-position: -4229px 0px;
}
.iti__flag.iti__ro {
	height: 14px;
	background-position: -4251px 0px;
}
.iti__flag.iti__rs {
	height: 14px;
	background-position: -4273px 0px;
}
.iti__flag.iti__ru {
	height: 14px;
	background-position: -4295px 0px;
}
.iti__flag.iti__rw {
	height: 14px;
	background-position: -4317px 0px;
}
.iti__flag.iti__sa {
	height: 14px;
	background-position: -4339px 0px;
}
.iti__flag.iti__sb {
	height: 10px;
	background-position: -4361px 0px;
}
.iti__flag.iti__sc {
	height: 10px;
	background-position: -4383px 0px;
}
.iti__flag.iti__sd {
	height: 10px;
	background-position: -4405px 0px;
}
.iti__flag.iti__se {
	height: 13px;
	background-position: -4427px 0px;
}
.iti__flag.iti__sg {
	height: 14px;
	background-position: -4449px 0px;
}
.iti__flag.iti__sh {
	height: 10px;
	background-position: -4471px 0px;
}
.iti__flag.iti__si {
	height: 10px;
	background-position: -4493px 0px;
}
.iti__flag.iti__sj {
	height: 15px;
	background-position: -4515px 0px;
}
.iti__flag.iti__sk {
	height: 14px;
	background-position: -4537px 0px;
}
.iti__flag.iti__sl {
	height: 14px;
	background-position: -4559px 0px;
}
.iti__flag.iti__sm {
	height: 15px;
	background-position: -4581px 0px;
}
.iti__flag.iti__sn {
	height: 14px;
	background-position: -4603px 0px;
}
.iti__flag.iti__so {
	height: 14px;
	background-position: -4625px 0px;
}
.iti__flag.iti__sr {
	height: 14px;
	background-position: -4647px 0px;
}
.iti__flag.iti__ss {
	height: 10px;
	background-position: -4669px 0px;
}
.iti__flag.iti__st {
	height: 10px;
	background-position: -4691px 0px;
}
.iti__flag.iti__sv {
	height: 12px;
	background-position: -4713px 0px;
}
.iti__flag.iti__sx {
	height: 14px;
	background-position: -4735px 0px;
}
.iti__flag.iti__sy {
	height: 14px;
	background-position: -4757px 0px;
}
.iti__flag.iti__sz {
	height: 14px;
	background-position: -4779px 0px;
}
.iti__flag.iti__ta {
	height: 10px;
	background-position: -4801px 0px;
}
.iti__flag.iti__tc {
	height: 10px;
	background-position: -4823px 0px;
}
.iti__flag.iti__td {
	height: 14px;
	background-position: -4845px 0px;
}
.iti__flag.iti__tf {
	height: 14px;
	background-position: -4867px 0px;
}
.iti__flag.iti__tg {
	height: 13px;
	background-position: -4889px 0px;
}
.iti__flag.iti__th {
	height: 14px;
	background-position: -4911px 0px;
}
.iti__flag.iti__tj {
	height: 10px;
	background-position: -4933px 0px;
}
.iti__flag.iti__tk {
	height: 10px;
	background-position: -4955px 0px;
}
.iti__flag.iti__tl {
	height: 10px;
	background-position: -4977px 0px;
}
.iti__flag.iti__tm {
	height: 14px;
	background-position: -4999px 0px;
}
.iti__flag.iti__tn {
	height: 14px;
	background-position: -5021px 0px;
}
.iti__flag.iti__to {
	height: 10px;
	background-position: -5043px 0px;
}
.iti__flag.iti__tr {
	height: 14px;
	background-position: -5065px 0px;
}
.iti__flag.iti__tt {
	height: 12px;
	background-position: -5087px 0px;
}
.iti__flag.iti__tv {
	height: 10px;
	background-position: -5109px 0px;
}
.iti__flag.iti__tw {
	height: 14px;
	background-position: -5131px 0px;
}
.iti__flag.iti__tz {
	height: 14px;
	background-position: -5153px 0px;
}
.iti__flag.iti__ua {
	height: 14px;
	background-position: -5175px 0px;
}
.iti__flag.iti__ug {
	height: 14px;
	background-position: -5197px 0px;
}
.iti__flag.iti__um {
	height: 11px;
	background-position: -5219px 0px;
}
.iti__flag.iti__un {
	height: 14px;
	background-position: -5241px 0px;
}
.iti__flag.iti__us {
	height: 11px;
	background-position: -5263px 0px;
}
.iti__flag.iti__uy {
	height: 14px;
	background-position: -5285px 0px;
}
.iti__flag.iti__uz {
	height: 10px;
	background-position: -5307px 0px;
}
.iti__flag.iti__va {
	height: 15px;
	background-position: -5329px 0px;
}
.iti__flag.iti__vc {
	height: 14px;
	background-position: -5346px 0px;
}
.iti__flag.iti__ve {
	height: 14px;
	background-position: -5368px 0px;
}
.iti__flag.iti__vg {
	height: 10px;
	background-position: -5390px 0px;
}
.iti__flag.iti__vi {
	height: 14px;
	background-position: -5412px 0px;
}
.iti__flag.iti__vn {
	height: 14px;
	background-position: -5434px 0px;
}
.iti__flag.iti__vu {
	height: 12px;
	background-position: -5456px 0px;
}
.iti__flag.iti__wf {
	height: 14px;
	background-position: -5478px 0px;
}
.iti__flag.iti__ws {
	height: 10px;
	background-position: -5500px 0px;
}
.iti__flag.iti__xk {
	height: 15px;
	background-position: -5522px 0px;
}
.iti__flag.iti__ye {
	height: 14px;
	background-position: -5544px 0px;
}
.iti__flag.iti__yt {
	height: 14px;
	background-position: -5566px 0px;
}
.iti__flag.iti__za {
	height: 14px;
	background-position: -5588px 0px;
}
.iti__flag.iti__zm {
	height: 14px;
	background-position: -5610px 0px;
}
.iti__flag.iti__zw {
	height: 10px;
	background-position: -5632px 0px;
}

.iti__flag {
	height: 15px;
	box-shadow: 0px 0px 1px 0px #888;
	background-image: url('https://cdn.mojoauth.com/icons/flags.png');
	background-repeat: no-repeat;
	background-color: #dbdbdb;
	background-position: 20px 0;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-image: url('https://cdn.mojoauth.com/icons/flags@2x.png');
	}
}

.iti__flag.iti__np {
	background-color: transparent;
}

</style>`
}

export { getStyle};