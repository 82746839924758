interface Request {
	method: string;
	credentials?:RequestCredentials;
	url: string;
	queryParams?: any;
	body?: any;
	headers?: any;
}

export const request = function (request: Request): any {
	let { method, credentials, url, queryParams = {}, body, headers = {} } = request;
	credentials=credentials?credentials:"same-origin";
	const urlWithQueryParams = Object.keys(queryParams).length
		? `${url}?${buildUrl(url, queryParams)}`
		: url;
	return fetch(urlWithQueryParams, {
		method,
		credentials,
		mode:"cors",
		headers,
		body: body ? JSON.stringify(body) : null,
	}).then((response) => {return response.json()});
};

const buildUrl = function (url: string, queryParams: any) {
	let urlString = '';
	for (const key in queryParams) {
		urlString += `${encodeURIComponent(key)}=${encodeURIComponent(
			queryParams[key]
		)}&`;
	}
	return urlString.substring(0, urlString.length - 1);
};
