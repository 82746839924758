import { Defaults } from '../../defaults';

export function createLoader(btnColor): void {
	const loginCardContainer = document.getElementById(Defaults.loginCardContainer);
	const loginForm = document.getElementById(Defaults.loginFormId);
	const loginPasswordId = document.getElementById(Defaults.loginPasswordId);
	const brandFooter = document.getElementById(Defaults.brandFooterId);
	const domainErrorMessage = document.getElementById(Defaults.domainErrorMessage);
	if (!loginCardContainer) return;

	if(loginForm){
		loginCardContainer.removeChild(<HTMLDivElement>loginForm);
	}
	if(loginPasswordId){
		loginCardContainer.removeChild(<HTMLDivElement>loginPasswordId);
	}
	if(domainErrorMessage){
		loginCardContainer.removeChild(domainErrorMessage)
	}
	const loadingDiv = document.createElement('div');
	loadingDiv.id= 'mojoauth-loader'
	loadingDiv.classList.add('mojoauth--loader');
	loadingDiv.style.setProperty('--custom-color', btnColor)
	loginCardContainer.insertBefore(loadingDiv, brandFooter);
}
