import { getConfig } from '../config';
import { request } from '../helpers/request';

const url = `${getConfig().apiUrl}/users/emailotp`;

/**
 *
 * @param apiKey
 * @param email
 * @returns
 * @description: post email to get otp on the user's email
 */
export function postOTPEmail(apikey: string, email: string, language: string): any {
	return request({
		method: 'POST',
		url,
		queryParams:{
			language
		},
		body: {
			email,
		},
		headers: {
			'X-API-Key': apikey,
		},
	});
}
