import {ping} from "../api/ping";
import {webauthnRegistered} from "../api/webauthnCheck";
import {webauthnFlagUpdate} from "../api/webauthnFlagUpdate";
import {getProjectConfig} from "../helpers/projectConfig";
import {createLoader} from "./ui-helpers/createLoader";
import {enableWebauthn} from "./webauthn/enableWebauthn";

export async function signInWithStateID(): Promise<any> {

    const apikey = this.apikey;
    const webauthn_forced = this.config?.enable_webauthn;
    const language = this.config?.language;
    let projectConfig;
    try {
        projectConfig = await getProjectConfig(apikey, language);
    } catch (e) {
        console.error(e.message);
        return;
    }
    // let promise=new Promise(function(resolve,reject){
    // 	const callback=async (response: any)=>{
    // 		const check= await webauthnRegistered(apikey,response.user.identifier);
    // 		console.log(check)
    // 		console.log((projectConfig.webauthn||webauthn_forced) && check.first_login)
    // 		if ((projectConfig.webauthn||webauthn_forced) && check.first_login){
    // 			enableWebauthn(response.oauth.access_token, apikey).then(response=> {console.log('webauthn response');console.log(response);resolve(response)})
    // 		}

    // 	resolve(response)
    // }

    let promise = new Promise(function (resolve, reject) {
        const callback = async (response: any) => {
            const check = await webauthnRegistered(apikey, response.user.identifier);
            if ((projectConfig.webauthn || webauthn_forced) && check.webauthn_flag) {
                enableWebauthn(response.oauth.access_token, apikey).then(response => resolve(response)).catch(async error => {
                    await webauthnFlagUpdate(apikey, response.oauth.access_token);
                    resolve(response)
                })
            } else resolve(response)
        }
        // createLoader();
        if (window.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            const state_id = urlParams.get('state_id');
            // ping api will take callback and will call it on successful response
            /**
             * If I can somehow make the code wait here unless I get the success response it will be great
             */
            ping(apikey, state_id, callback)

        }
    })
    return promise
}
