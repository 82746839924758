import { request } from '../helpers/request';
import { getConfig } from '../config';

const url = `${getConfig().apiUrl}/webauthn/userflag`;

export function webauthnFlagUpdate(apikey:string, token:string ): Promise<any> {
	return request({
		method: 'POST',
		url,
        body:{
            
            "webauthn_flag": "disable"
                
        },
		headers: {
			'X-API-Key': apikey,
            Authorization: `Bearer ${token}`,
		},
	});
}